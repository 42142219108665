import { formatNumber } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { get, initial, isNil, isNumber, last, memoize, range, sum } from 'lodash-es';
import { Observable } from 'rxjs';
import { MultiSelectDropdownComponent, Option } from '@cumlaude/shared-components-inputs';
import { aggDecimals, maxOver, noAgg, noAgg0, noAggString, SingleAggregator, sumOver, weightedAverage } from '../../services/aggregation';
import { getLeafA, Level, Path } from '../../services/data-tree';
import {
	AttrPath,
	BasicFilterExpression,
	CijferMeasure,
	CompoundFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	ExportDataOptions,
	FilterExpression,
} from '../../services/data.service';
import { FilterService } from '../../services/filter.service';
import { FilterName } from '../../services/filter-config';
import { att, percOfRow } from '../../services/measures';
import { LabelCellComponent } from '../../shared/components/table/cells/label-cell/label-cell.component';
import { ColumnDef, TableModel } from '../../shared/components/table/table/table.model';
import { deelVeilig, generateCssClassForCijfer } from '@cumlaude/shared-utils';
import { BarInfo } from '../../services/stacked-bars';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { createMeasureColumn, DataRow } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { ActivatedRoute } from '@angular/router';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes, LinkData } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { FactTable } from '../../services/exportable';
import { ToastrService } from 'ngx-toastr';
import { Axis } from '../../services/axis';
import { UrlService } from '../../services/url.service';
import { CijferColumn, DashboardVariant } from '../../services/weergave-opties';
import { PartitionMeasure, VbarchartTableComponent } from '../../shared/dashboard/vbarchart-table/vbarchart-table.component';
import { formatDecimal } from '@cumlaude/shared-pipes';
import { TooltipElement } from '@cumlaude/shared-components-overlays';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { getBijzonderheidLabel } from '../../services/cijfers';
import { UserService } from '../../services/user.service';
import { InstellingBron } from '@cumlaude/service-contract';

export interface CijferHistogram extends Attributes {
	/** Histogram met aantallen per afgerond cijfer */
	cf_nr_aantal_0: number;
	cf_nr_aantal_1: number;
	cf_nr_aantal_2: number;
	cf_nr_aantal_3: number;
	cf_nr_aantal_4: number;
	cf_nr_aantal_5: number;
	cf_nr_aantal_6: number;
	cf_nr_aantal_7: number;
	cf_nr_aantal_8: number;
	cf_nr_aantal_9: number;
	cf_nr_aantal_10: number;
}

export interface CijferI extends CijferHistogram {
	cf_nr_cijfer_afgerond: number;
	cf_nr_cijfer: number;
	cf_nr_decimalen: number;
	cf_nr_cijfer_vorig_sj: number;
	cf_nr_gem_cijfer_andere_vakken: number;
	cf_nr_tekortpunten: number;
	cf_nr_leerlingen: number;
	cf_nr_cijfer_mediaan: number;
	cf_nr_perc_geoorloofd_afwezig: number;
	cf_nr_perc_ongeoorloofd_afwezig: number;
	cf_nr_sectiegemiddelde: number;
	cf_fun_plaatsing_advies_score: number;
	cf_nr_aantal_toetsen: number;
	cf_is_gevuld: number;
	cf_nr_label_onvoldoendes: number; // | null
	clc_abb_label_avg: any;
	cf_nm_bijzonderheid: any;
}

export interface CijferA extends Attributes {
	cijfer: number | null;
	cijferVorigSchooljaar: number | null;
	cijferAndereVakken: number | null;
	decimalen: number;
	label: string;
	bijzonderheid: string;
	tekortpunten: number;
	tekortpuntenPerLeerling: number;
	aantalLeerlingen: number;
	onvoldoendes: number;
	cijferAfgerondOV: any; // number | 'onvoldoende' | 'voldoende';
	cijferMediaan: number | null;
	percGeoorloofdAfwezig: number | null;
	percOngeoorloofdAfwezig: number | null;
	sectiegemiddelde: number;
	plaatsingAdviesScore: number | null;
	gemiddeldAantalToetsen: number | null;
	max: number;
	aantalCijfers: number;
	cf_nr_label_onvoldoendes: number | null;
}

export function cijferOpties(resultaat: number | string): string {
	if (isNumber(resultaat))
		return ['nullen', 'enen', 'tweeën', 'drieën', 'vieren', 'vijven', 'zessen', 'zevens', 'achten', 'negens', 'tienen'][resultaat];
	return `${resultaat}s`; // voldoendes/onvoldoendes
}

const aanwezigheidColumns = [CijferColumn.PercAfwezigheidGeoorloofd, CijferColumn.PercAfwezigheidOngeoorloofd];

@Component({
	selector: 'app-cijfers-overzicht',
	templateUrl: './cijfers-overzicht.component.html',
	styleUrls: ['./cijfers-overzicht.component.scss'],
	standalone: true,
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		MultiSelectDropdownComponent,
		BarchartTableComponent,
		VbarchartTableComponent,
	],
})
export class CijfersOverzichtComponent extends BarchartTableConfig<CijferI, CijferA> implements OnInit {
	defaultGroups: AttrPath[] = [['cf_fk_ilt', 'ilt_nm_niveau'], ['cf_nr_leerjaar']];

	groups: AttrPath[] = this.defaultGroups;

	availableGroups: AttrPath[] = [
		['cf_fk_ll', 'll_nm_basisschooladvies_uni'],
		['cf_fk_ll', 'll_nm_basisschooladvies_uni_herzien'],
		['cf_fk_ll', 'll_nm_basisschooladvies_duo'],
		['cf_fk_ll', 'll_nm_svh'],
		['cf_fk_lb', 'lb_nm_uitstroomprofiel_vso'],
		['cf_fks_mw', 'mw_nm_medewerker'],
		['cf_nm_klas'],
		['cf_nr_leerjaar'],
		['cf_nm_leerling'],
		['cf_nm_lesgroep'],
		['cf_nm_lesgroep_docenten'],
		['cf_nm_opleiding'],
		['cf_fk_ilt', 'ilt_nm_niveau'],
		['cf_fk_ilt_vorig_sj', 'ilt_nm_niveau'],
		['cf_fk_ilt', 'ilt_abb_profiel'],
		['cf_fun_nm_vak_uni'],
		['cf_nm_vak'],
		['cf_nr_leerjaar_vak'],
		['cf_abb_onderwijssoort_vak'],
		['cf_fk_vk', 'vk_nm_vakkengroep'],
		['cf_fk_vk', 'vk_nm_vak'],
		['cf_nm_vestiging'],
		['cf_fk_lb_vorig_sj', 'lb_nm_vestiging'],
		['cf_fk_lb', 'lb_nm_leerfase'],
		['cf_fk_lb_vorig_sj', 'lb_nm_leerfase'],
		['cf_fun_periode'],
		['cf_abb_kolomkop'],
		['cf_nr_kolom'],
	];

	actueelFilters: FilterName[] = [
		'cf_nm_schooljaar',
		'cf_fk_lb.lb_co_brin',
		'cf_nm_vestiging',
		'cf_fk_ilt.ilt_nm_niveau',
		'cf_nr_leerjaar',
		'x_cijfertype',
		'x_gem_periode',
		'cf_nm_vak',
		'cf_nm_lesgroep',
		'x_cf_is_alternatievenormering',
	];

	historieFilters: FilterName[] = [
		'x_cijfer_schooljaar_historie', //
		'x_cijfer_multiselect_schooljaar',
		...this.actueelFilters.filter((f) => !['cf_nm_lesgroep'].includes(f)),
	];

	filterExpressions?: FilterExpression[];

	permanentFilterExpressions: FilterExpression[] = [];

	variant = DashboardVariant.ACTUEEL;

	factTable = FactTable.cijfers;

	protected singleAggregators: Partial<{ [ai in keyof CijferA]: SingleAggregator<CijferI, CijferA[ai]> }> = {
		aantalLeerlingen: noAgg0('cf_nr_leerlingen'),
		tekortpunten: sumOver('cf_nr_tekortpunten'),
		onvoldoendes: {
			init: (i: CijferI) => i.cf_nr_label_onvoldoendes ?? sum(range(0, 6).map((n) => get(i, 'cf_nr_aantal_' + n, 0))),
			combine: (as: number[]) => sum(as),
		},
		max: maxOver('cf_nr_cijfer'),
		aantalCijfers: sumOver('cf_is_gevuld'),
		cf_nr_label_onvoldoendes: sumOver('cf_nr_label_onvoldoendes'),
		cijferMediaan: noAgg('cf_nr_cijfer_mediaan'),
		percGeoorloofdAfwezig: noAgg('cf_nr_perc_geoorloofd_afwezig'),
		percOngeoorloofdAfwezig: noAgg('cf_nr_perc_ongeoorloofd_afwezig'),
		decimalen: aggDecimals('cf_nr_decimalen'),
		label: noAggString('clc_abb_label_avg'),
		bijzonderheid: noAggString('cf_nm_bijzonderheid'),
	};

	protected multiAggregators = [
		weightedAverage('cijfer', 'cf_nr_cijfer', 'aantalCijfers'),
		weightedAverage('cijferVorigSchooljaar', 'cf_nr_cijfer_vorig_sj'),
		weightedAverage('cijferAndereVakken', 'cf_nr_gem_cijfer_andere_vakken'),
		weightedAverage('sectiegemiddelde', 'cf_nr_sectiegemiddelde'),
		weightedAverage('plaatsingAdviesScore', 'cf_fun_plaatsing_advies_score'),
		weightedAverage('gemiddeldAantalToetsen', 'cf_nr_aantal_toetsen'),
	];

	private readonly cijferValue = (path: Path<CijferA, number[]>) => {
		const { label, bijzonderheid, cijfer } = getLeafA(path);
		const bijzonderheidLabel = getBijzonderheidLabel(bijzonderheid, cijfer, label);
		return bijzonderheidLabel ?? label ?? cijfer;
	};

	private readonly cijferFormat = (path: Path<CijferA, number[]>) => {
		const { decimalen } = getLeafA(path);
		return `1.${decimalen}-${decimalen}`;
	};

	private readonly cijferType = (path: Path<CijferA, number[]>) => {
		const { label, bijzonderheid } = getLeafA(path);
		return label || bijzonderheid ? 'string' : 'number';
	};

	columnMultiSelectOptions: Option<CijferColumn>[] = Object.values(CijferColumn).map((column) => new Option<CijferColumn>(column));

	visibleColumns!: CijferColumn[];

	constructor(
		protected dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected activatedRoute: ActivatedRoute,
		protected toastr: ToastrService,
		protected urlService: UrlService,
		userService: UserService
	) {
		super(filterService, toastr);
		if (userService.bron() === InstellingBron.Magister) {
			this.actueelFilters.push('cf_abb_kolomkop', 'cf_nr_kolom');
		}
	}

	ngOnInit(): void {
		this.subscribeToQueryParams();
	}

	subscribeToQueryParams() {
		this.subscriptions.push(
			this.qp.observe_g().subscribe((groups) => (this.groups = groups ?? this.defaultGroups)),
			this.qp.observe('col').subscribe((cols) => this.setVisibleColumns(cols)),
			this.qp.observe('variant').subscribe((variant) => (this.variant = variant))
		);
	}

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getCijfersData({
			...options,
			m: [
				CijferMeasure.HISTOGRAM,
				CijferMeasure.RELATIE,
				CijferMeasure.STATS,
				CijferMeasure.LABEL,
				CijferMeasure.BIJZONDERHEID,
				...(this.visibleColumns?.includes(CijferColumn.PlaatsingAdvies) ? [CijferMeasure.PLAATSING_ADVIES] : []),
				...(this.visibleColumns?.some((column) => aanwezigheidColumns.includes(column)) ? [CijferMeasure.AANWEZIGHEID] : []),
			],
			r: options.g!.length > 0 ? [0] : undefined,
		});
	}

	getExportData(options: ExportDataOptions) {
		return this.dataService.getCijfersExportData(options);
	}

	createMeasureColumns(context: DashboardContext<CijferI, CijferA, CijfersOverzichtComponent>): ColumnDef<DataRow<CijferA>>[] {
		if (this.variant !== DashboardVariant.ACTUEEL) return [];

		const columns: ColumnDef<DataRow<CijferA>>[] = [
			createMeasureColumn(CijferColumn.AantalCijfers, att('aantalCijfers')),
			createMeasureColumn(CijferColumn.Cijfer, this.cijferValue, { format: this.cijferFormat, dataType: this.cijferType }),
			createMeasureColumn(CijferColumn.GemAndereVakken, att('cijferAndereVakken'), { format: '1.1-1' }),
			createMeasureColumn(CijferColumn.Sectiegemiddelde, att('sectiegemiddelde'), { format: '1.1-1' }),
			createMeasureColumn(CijferColumn.GemAantalToetsen, att('gemiddeldAantalToetsen')),
			createMeasureColumn(CijferColumn.CijferVorigSchooljaar, att('cijferVorigSchooljaar'), { format: '1.1-1' }),
			createMeasureColumn(CijferColumn.Tekortpunten, att('tekortpunten')),
			createMeasureColumn(CijferColumn.GemTekortpunten, percOfRow('tekortpunten', 'aantalLeerlingen')),
			createMeasureColumn(CijferColumn.Leerlingen, att('aantalLeerlingen'), {
				clickHandler: (rowModel) => this.handleLeerlingenRedirect(rowModel, context),
			}),
			createMeasureColumn(CijferColumn.PercOnvoldoende, percOfRow('onvoldoendes', 'aantalCijfers'), { dataType: 'percentage' }),
			createMeasureColumn(CijferColumn.Mediaan, att('cijferMediaan'), { format: '1.1-1' }),
			createMeasureColumn(
				CijferColumn.PlaatsingAdvies,
				(path) => {
					const score = getLeafA(path).plaatsingAdviesScore;
					if (isNil(score)) return '-';
					const formatted = formatNumber(score, 'nl-NL', '1.1-1');
					if (score < -0.05) return `${formatted} (onder adv.)`;
					if (score >= 0.05) return `+${formatted} (boven adv.)`;
					return '0,0 (op adv.)';
				},
				{ component: LabelCellComponent }
			),
			createMeasureColumn(CijferColumn.PercAfwezigheidOngeoorloofd, att('percOngeoorloofdAfwezig'), {
				dataType: 'percentage',
				format: '1.2-2',
			}),
			createMeasureColumn(CijferColumn.PercAfwezigheidGeoorloofd, att('percGeoorloofdAfwezig'), {
				dataType: 'percentage',
				format: '1.2-2',
			}),
		];

		columns.forEach((col) => (col.visible = this.visibleColumns.includes(<CijferColumn>col.column)));

		return columns;
	}

	isHistorieBatchVariant(): boolean {
		return this.variant === DashboardVariant.HISTORIE && this.groups.length > 0;
	}

	protected handleLeerlingenRedirect(path: Path<CijferA, number[]>, context: DashboardContext<CijferI, CijferA, CijfersOverzichtComponent>) {
		// this.createLinkData maakt een zoom-link, daarom super gebruiken
		const linkData = super.createLinkData(path, context);
		this.urlService.navigate({
			...linkData,
			dashboard: '/details/leerling/cijferlijst',
			dataProvider: 'cijfers',
		});
	}

	setVisibleColumns(columns: CijferColumn[]) {
		this.visibleColumns = columns;
		this.filterService.refresh();
	}

	getBarchartQty(path: Path<CijferA, number[]>): number {
		return Math.min(getLeafA(path).cijfer ?? 0, 10);
	}

	createXAxis(_context: DashboardContext<CijferI, CijferA, CijfersOverzichtComponent>): Axis {
		return { min: 0, max: 10, ticks: [] };
	}

	partitionBarData(rowRoot: Level<CijferA, number[]>): Path<CijferA, number[]>[][] {
		return partitionHistogram(rowRoot);
	}

	makeBar(attrs: CijferI, path: Path<CijferA, number[]>, context: DashboardContext<CijferI, CijferA, CijfersOverzichtComponent>): BarInfo {
		const { className, size, sizeLabel } = makeHistogramBar(attrs, path);
		return {
			className,
			size,
			linkData: this.createLinkData(path, context),
			tooltip: this.createTooltip(path, sizeLabel, size),
		};
	}

	createTooltip(path: Path<CijferA, number[]>, sizeLabel: string, size: number): TooltipElement[] {
		const { cijfer, aantalLeerlingen, aantalCijfers, onvoldoendes } = last(path)!.a;
		if (this.variant === DashboardVariant.ACTUEEL) {
			return [{ label: sizeLabel, value: `${size}` }];
		} else {
			return [
				{ label: 'Gemiddeld cijfer', value: formatDecimal(cijfer, '1.1-1') },
				{ label: '% onvoldoende', value: `${formatNumber(deelVeilig(onvoldoendes, aantalCijfers) * 100, 'nl-NL', '1.0-0')}%` },
				{ label: 'Leerlingen', value: aantalLeerlingen.toString() },
			];
		}
	}

	createLinkData(path: Path<CijferA, number[]>, context: DashboardContext<CijferI, CijferA, CijfersOverzichtComponent>): Partial<LinkData> {
		const groups = this.variant === DashboardVariant.ACTUEEL ? [...context.groupNames] : [...context.groupNames, context.subgroupNames[0]];
		const linkData = super.createLinkData(path, context);
		const cijfersOverzichtFilter = new CompoundFilterExpression([
			<FilterExpression>linkData.filter!,
			new BasicFilterExpression(['cf_nm_leerling'], last(path)!.k),
		]);

		return this.createZoomLinkData(['cf_nm_vak', 'cf_nm_lesgroep', 'cf_nm_leerling'], groups, path, {
			...linkData,
			dashboard: '/details/leerling/cijferlijst',
			dataProvider: 'cijfers',
			filter: cijfersOverzichtFilter,
		});
	}

	partitionMeasure: PartitionMeasure<CijferA> = { type: this.cijferType, getValue: this.cijferValue, format: this.cijferFormat };

	// memoize, otherwise new array keeps triggering change detection
	getHistorieGroups = memoize(CijfersOverzichtComponent._getHistorieGroups, JSON.stringify);

	private static _getHistorieGroups(selectedGroups: AttrPath[]) {
		return selectedGroups.slice(0, -1);
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieSubgroups = memoize(CijfersOverzichtComponent._getHistorieSubgroups, JSON.stringify);

	private static _getHistorieSubgroups(selectedGroups: AttrPath[]): AttrPath[] {
		return [...selectedGroups.slice(-1), ['cf_nm_schooljaar']];
	}

	enrichTableModel(_context: DashboardContext<CijferI, CijferA, CijfersOverzichtComponent>, tableModel: TableModel<DataRow<CijferA>>) {
		tableModel.showFooters = this.variant === 'Actueel';
	}

	createYAxis(context: DashboardContext<CijferI, CijferA, CijfersOverzichtComponent>): Axis {
		// ticks voor 0 t/m 10 met stapjes van 2
		// const ticks = range(0, 12, 2).map((qty) => ({ qty, label: `${qty}` }));
		return { min: 0, max: context.dataRoot!.a.max, ticks: [] };
	}
}

export function partitionHistogram<A extends { cf_nr_label_onvoldoendes: number | null }>(
	rowRoot: Level<A & { cijferAfgerondOV: any }, number[]>
): Path<A, number[]>[][] {
	const path = rowRoot.r[0];
	if (!path) return [];
	const a = last(path)!.a;
	const bins = a.cf_nr_label_onvoldoendes === null ? range(0, 11) : ['onvoldoende', 'voldoende'];
	// genereer records met cijferAfgerondOV van 0 t/m 10
	return [bins.map((cijferAfgerondOV) => [...initial(path), { ...last(path)!, a: { ...a, cijferAfgerondOV } }])];
}

export function getCijferSize<I, A extends { cf_nr_label_onvoldoendes: number | null; cijferAfgerondOV: any; aantalCijfers: number }>(
	attrs: I,
	path: Path<A, number[]>
) {
	const { cijferAfgerondOV, cf_nr_label_onvoldoendes, aantalCijfers } = last(path)!.a;
	let size: number;
	if (cijferAfgerondOV === 'onvoldoende') size = cf_nr_label_onvoldoendes!;
	else if (cijferAfgerondOV === 'voldoende') size = aantalCijfers - cf_nr_label_onvoldoendes!;
	else size = get(attrs, 'cf_nr_aantal_' + cijferAfgerondOV, 0);
	return size;
}

export function makeHistogramBar<
	I extends CijferHistogram,
	A extends { cf_nr_label_onvoldoendes: number | null; cijferAfgerondOV: any; aantalCijfers: number },
>(attrs: I, path: Path<A, number[]>): { className: string; sizeLabel: string; size: number } {
	const size = getCijferSize(attrs, path);
	const { cijferAfgerondOV } = last(path)!.a;
	const sizeLabel = `Aantal ${cijferOpties(cijferAfgerondOV)}`;
	const className = generateCssClassForCijfer(cijferAfgerondOV);
	return { sizeLabel, size, className };
}
