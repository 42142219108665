import { Component, effect, OnInit, Signal, signal } from '@angular/core';
import { createMeasureColumn, DataRow } from '../../shared/dashboard/data-tree-table/data-tree-table';
import {
	AanwezigheidMeasure,
	AttrPath,
	BasicFilterExpression,
	CompoundFilterExpression,
	DataResponse,
	DataService,
	ExportDataOptions,
	FilterExpression,
} from '../../services/data.service';
import { FilterName } from '../../services/filter-config';
import { Observable } from 'rxjs';
import { FilterService } from '../../services/filter.service';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { maxOverAtLevel, MultiAggregator, noAgg0, noAggFiltered0, xAgg0 } from '../../services/aggregation';
import { getLeafA, Level, Path } from '../../services/data-tree';
import { att, percOfRow } from '../../services/measures';
import { ColumnDef } from '../../shared/components/table/table/table.model';
import { fromPairs, intersection, isNil, last, max, memoize, nth, orderBy } from 'lodash-es';
import { BarInfo } from '../../services/stacked-bars';
import { PointInfo } from '../../shared/dashboard/linechart-table/linechart/linechart.component';
import { deelVeilig, formatDurationUrenMinuten, generateCssClassForString } from '@cumlaude/shared-utils';
import { formatNumber, formatPercent } from '@angular/common';
import { getAbsentieKlasse } from '../../core/services/enum.service';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { LinechartTableConfig } from '../../shared/dashboard/linechart-table/linechart-table-config';
import { Attributes, LinkData } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { FactTable } from '../../services/exportable';
import { AbsentieKlasseHistorie } from '../../services/label-enums';
import { ToastrService } from 'ngx-toastr';
import { Axis, createYAxis } from '../../services/axis';
import { DashboardVariant, Interval, Tijdseenheid } from '../../services/weergave-opties';
import { ExportType } from '../../services/export.service';
import { formatDecimal } from '@cumlaude/shared-pipes';
import { LinechartTableComponent } from '../../shared/dashboard/linechart-table/linechart-table.component';
import { BarchartTableComponent } from '../../shared/dashboard/barchart-table/barchart-table.component';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';
import { WeergaveOptieComponent } from '../../shared/components/weergave-optie/weergave-optie.component';

interface AfwezigheidI extends Attributes {
	aw_nr_lesuren: number;
	aw_nr_lesuren_filtered: number;
	aw_nr_lesdagen: number;
	aw_nr_lesdagen_filtered: number;
	aw_nr_minuten: number;
	aw_nr_minuten_filtered: number;
	nr_leerlingen: number;
	aw_is_abs_geoorloofd: '0' | '1' | null;
	xa: { [nr: number]: { aw_nr_lesuren: number } };
}

interface AfwezigheidA extends Attributes {
	aw_nr_lesuren: number;
	aw_nr_lesuren_x: number;
	aw_nr_lesuren_filtered: number;
	max_uren_filtered: number;
	aw_nr_lesdagen: number;
	aw_nr_lesdagen_x: number;
	aw_nr_lesdagen_filtered: number;
	max_dagen_filtered: number;
	aw_nr_minuten: number;
	aw_nr_minuten_x: number;
	aw_nr_minuten_filtered: number;
	max_minuten_filtered: number;
	nr_leerlingen_filtered: number;
}

@Component({
	selector: 'app-afwezigheid-overzicht',
	templateUrl: './afwezigheid-overzicht.component.html',
	styleUrls: ['./afwezigheid-overzicht.component.scss'],
	standalone: true,
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		BarchartTableComponent,
		LinechartTableComponent,
		WeergaveOptieComponent,
	],
})
export class AfwezigheidOverzichtComponent extends BarchartTableConfig<AfwezigheidI, AfwezigheidA> implements OnInit {
	defaultGroups: AttrPath[] = [['aw_fk_ilt', 'ilt_nm_niveau'], ['aw_nr_leerjaar']];

	selectedGroups = signal(this.defaultGroups);

	availableGroups: AttrPath[] = [
		['aw_fk_ilt', 'ilt_nm_niveau'],
		['aw_nr_leerjaar'],
		['aw_fk_ilt', 'ilt_nm_opleiding'],
		['aw_nm_klas'],
		['aw_fk_ilt', 'ilt_abb_profiel'],
		['aw_nm_abs_reden'],
		['aw_fk_vs', 'vs_nm_vestiging'],
		['aw_fk_ll', 'll_nm_leerling'],
		['aw_fks_mw', 'mw_nm_medewerker'],
		['aw_nm_vak'],
	];

	fixedSubgroups: AttrPath[] = [['aw_is_abs_geoorloofd']];

	actueelFilters: FilterName[] = [
		'aw_nm_schooljaar',
		'aw_fk_lb.lb_co_brin',
		'aw_fk_vs.vs_nm_vestiging',
		'aw_fk_ilt.ilt_nm_niveau',
		'aw_nr_leerjaar',
		'aw_nm_klas',
		'aw_is_abs_geoorloofd',
		'aw_nm_abs_reden',
		'aw_nm_vak',
		'aw_d_datum.per_d_datum',
	];

	historieFilters: FilterName[] = [
		'aw_nm_schooljaar', // is onzichtbaar en filtert niet, maar is alleen om het schooljaar van de actueel-variant vast te houden
		'x_aw_schooljaar_historie',
		'x_aw_multiselect_schooljaar',
		'aw_fk_vs.vs_nm_vestiging',
		'aw_fk_ilt.ilt_nm_niveau',
		'aw_nr_leerjaar',
		'x_abs_geoorloofd',
		'aw_nm_klas',
		'aw_d_datum.per_d_datum',
	];

	filterExpressions?: FilterExpression[];

	permanentFilterExpressions = [];

	variant: Signal<DashboardVariant>;

	tijdseenheid = this.qp.signal('tijdseenheid');

	interval = this.qp.signal('interval');

	visibleLines: AbsentieKlasseHistorie[] = Object.values(AbsentieKlasseHistorie);

	protected actueelExportTypes = [ExportType.AFBEELDING, ExportType.PDF, ExportType.TABEL, ExportType.DATA];
	protected historieExportTypes = [ExportType.AFBEELDING, ExportType.PDF, ExportType.DATA];

	protected singleAggregators = {
		aw_nr_lesuren: noAgg0<'aw_nr_lesuren', AfwezigheidI>('aw_nr_lesuren'),
		aw_nr_lesuren_filtered: noAggFiltered0('aw_nr_lesuren'),
		aw_nr_lesdagen: noAgg0<'aw_nr_lesdagen', AfwezigheidI>('aw_nr_lesdagen'),
		aw_nr_lesdagen_filtered: noAggFiltered0('aw_nr_lesdagen'),
		aw_nr_minuten: noAgg0<'aw_nr_minuten', AfwezigheidI>('aw_nr_minuten'),
		aw_nr_minuten_filtered: noAggFiltered0('aw_nr_minuten'),
		nr_leerlingen: noAgg0<'nr_leerlingen', AfwezigheidI>('nr_leerlingen'),
		nr_leerlingen_filtered: noAggFiltered0('nr_leerlingen'),
		aw_nr_lesuren_x: xAgg0<'aw_nr_lesuren', AfwezigheidI>('aw_nr_lesuren'),
		aw_nr_lesdagen_x: xAgg0<'aw_nr_lesdagen', AfwezigheidI>('aw_nr_lesdagen'),
		aw_nr_minuten_x: xAgg0<'aw_nr_minuten', AfwezigheidI>('aw_nr_minuten'),
	};

	protected multiAggregators: MultiAggregator<keyof AfwezigheidA, AfwezigheidI, AfwezigheidA, number>[] = [
		maxOverAtLevel('max_uren_filtered', 'aw_nr_lesuren_filtered', () => this.selectedGroups().length),
		maxOverAtLevel('max_dagen_filtered', 'aw_nr_lesdagen_filtered', () => this.selectedGroups().length),
		maxOverAtLevel('max_minuten_filtered', 'aw_nr_minuten_filtered', () => this.selectedGroups().length),
	];

	linechartConfig = new (class extends LinechartTableConfig<AfwezigheidI, AfwezigheidA> {
		constructor(private dashboard: AfwezigheidOverzichtComponent) {
			super(dashboard.filterService, dashboard.toastr);
		}

		makePoints(r: Path<AfwezigheidA, number[]>[]): PointInfo[] {
			const totalClass = generateCssClassForString(AbsentieKlasseHistorie.Totaal);
			return [...this.dashboard.getVisibleQties(r)].map(([lineClass, qty]) => ({
				lineClass: lineClass == totalClass ? 'default-fill' : lineClass,
				qty,
			}));
		}

		/**
		 * maximum van de zichtbare lijnen (in relevante eenheid) van alle grafieken van de batch.
		 */
		getYAxis(path: Path<AfwezigheidA, number[]>): Axis {
			const batchMax = max(nth(path, -2)!.xr!.map((rec) => max([...this.dashboard.getVisibleQties([rec]).values()]))) || 100;

			switch (this.dashboard.tijdseenheid()) {
				case Tijdseenheid.UREN_PERCENTAGE:
					return createYAxis([0, batchMax], 5, (val) => formatPercent(val, 'nl_NL'));
				case Tijdseenheid.TIJD:
					return createYAxis([0, batchMax], 5, (val) => formatDurationUrenMinuten(val, 'h'));
				default:
					return createYAxis([0, batchMax], 5, (val) => formatDecimal(val, '1.0-1'));
			}
		}

		getLineNames(): { [lineClass: string]: string } {
			return {
				...fromPairs(Object.values(AbsentieKlasseHistorie).map((cls) => [generateCssClassForString(cls), cls])),
				'default-fill': 'Totaal',
			};
		}

		getQtyFormatter() {
			switch (this.dashboard.tijdseenheid()) {
				case Tijdseenheid.UREN_PERCENTAGE:
					return (val: number) => formatPercent(val, 'nl_NL', '1.2-2');
				case Tijdseenheid.TIJD:
					return (val: number) => formatDurationUrenMinuten(val, 'u m');
				default:
					return undefined;
			}
		}

		factTable = FactTable.aanwezigheid;
		getData = this.dashboard.getData.bind(this.dashboard);
		getExportData = this.dashboard.getExportData.bind(this.dashboard);
		isHistorieBatchVariant = this.dashboard.isHistorieBatchVariant.bind(this.dashboard);
		availableGroups = this.dashboard.availableGroups;
		singleAggregators = this.dashboard.singleAggregators;
		multiAggregators = this.dashboard.multiAggregators;
		enrichTableModel = this.dashboard.enrichTableModel.bind(this.dashboard);
	})(this);

	constructor(
		private dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
		this.subscriptions.push(
			filterService.observe('x_abs_geoorloofd').subscribe((visibleLines) => {
				this.visibleLines = visibleLines?.length ? visibleLines : Object.values(AbsentieKlasseHistorie);
			})
		);
		this.variant = qp.signal('variant');
		effect(() => {
			this.tijdseenheid();
			this.filterService.refresh();
		});
	}

	ngOnInit() {
		this.subscribeToQueryParams();
	}

	subscribeToQueryParams() {
		this.subscriptions.push(this.qp.observe_g().subscribe((groups) => this.selectedGroups.set(groups ?? this.defaultGroups)));
	}

	/**
	 * De getoonde waarden voor bar/line zijn afhankelijk van de gekozen Tijdseenheid.
	 */
	getRelevantQty(path: Path<AfwezigheidA, number[]>): number {
		return this.getQty(path, this.tijdseenheid());
	}

	getQty(path: Path<AfwezigheidA, number[]>, tijdseenheid: Tijdseenheid): number {
		const attrs = last(path)!.a;
		switch (tijdseenheid) {
			case Tijdseenheid.UREN:
				return attrs.aw_nr_lesuren;
			case Tijdseenheid.UREN_PERCENTAGE:
				return deelVeilig(attrs.aw_nr_lesuren, attrs.aw_nr_lesuren_x);
			case Tijdseenheid.DAGEN:
				return attrs.aw_nr_lesdagen;
			case Tijdseenheid.TIJD:
				return attrs.aw_nr_minuten;
		}
	}

	/**
	 * De totaal-lijn representeert de totale ABSENTIE en niet het totaal aantal uren/dagen.
	 */
	getRelevantTotal(path: Path<AfwezigheidA, number[]>): number {
		const attrs = nth(path, -2)!.a;
		switch (this.tijdseenheid()) {
			case Tijdseenheid.UREN:
				return attrs.aw_nr_lesuren_filtered;
			case Tijdseenheid.UREN_PERCENTAGE:
				return deelVeilig(attrs.aw_nr_lesuren_filtered, attrs.aw_nr_lesuren_x);
			case Tijdseenheid.DAGEN:
				return attrs.aw_nr_lesdagen_filtered;
			case Tijdseenheid.TIJD:
				return attrs.aw_nr_minuten_filtered;
		}
	}

	/**
	 * Genereer de waardes in de relevante eenheid voor de zichtbare lijnen (volgens het x_abs_geoorloofd "filter").
	 * Als er geen record is voor een bepaalde lijn wordt de waarde 0.
	 */
	getVisibleQties(recs: Path<AfwezigheidA, number[]>[]): Map<string, number> {
		const ret = new Map<string, number>();
		// onderstaande volgorde zorgt ervoor dat totaallijn over de andere getekend wordt
		intersection(
			[AbsentieKlasseHistorie.Ongeoorloofd, AbsentieKlasseHistorie.Geoorloofd, AbsentieKlasseHistorie.Totaal],
			this.visibleLines
		).forEach((clz) => ret.set(generateCssClassForString(clz), 0));

		for (const rec of recs) {
			const aw_is_abs_geoorloofd = last(rec)!.k;
			if (isNil(aw_is_abs_geoorloofd)) continue;

			const clz = generateCssClassForString(getAbsentieKlasse(aw_is_abs_geoorloofd));
			if (ret.get(clz) !== undefined) ret.set(clz, this.getRelevantQty(rec));
		}
		const totalClass = generateCssClassForString(AbsentieKlasseHistorie.Totaal);
		if (ret.get(totalClass) !== undefined && recs.length > 0) ret.set(totalClass, this.getRelevantTotal(recs[0]));
		return ret;
	}

	makeBar(
		attrs: AfwezigheidI,
		path: Path<AfwezigheidA, number[]>,
		context: DashboardContext<AfwezigheidI, AfwezigheidA, AfwezigheidOverzichtComponent>
	): BarInfo {
		if (isNil(attrs.aw_is_abs_geoorloofd)) {
			return {
				size: 0,
				linkData: {},
			};
		}

		const barInfo = super.makeBar(attrs, path, context);
		const size = this.getRelevantQty(path);
		const kls = getAbsentieKlasse(attrs.aw_is_abs_geoorloofd);
		return {
			...barInfo,
			size,
			className: generateCssClassForString(kls),
			tooltip: [
				{
					label: 'Absentie',
					value: kls,
				},
				...this.getQtyTooltip(path),
			],
		};
	}

	getQtyTooltip(path: Path<AfwezigheidA, number[]>) {
		switch (this.tijdseenheid()) {
			case Tijdseenheid.UREN_PERCENTAGE:
				return [
					{ label: Tijdseenheid.UREN_PERCENTAGE, value: formatPercent(this.getQty(path, Tijdseenheid.UREN_PERCENTAGE), 'nl', '1.1-1') },
				];
			case Tijdseenheid.DAGEN:
				return [{ label: Tijdseenheid.DAGEN, value: formatNumber(this.getQty(path, Tijdseenheid.DAGEN), 'nl-NL') }];
			default:
				return [
					{ label: Tijdseenheid.UREN, value: formatNumber(this.getQty(path, Tijdseenheid.UREN), 'nl-NL') },
					{ label: Tijdseenheid.TIJD, value: formatDurationUrenMinuten(this.getQty(path, Tijdseenheid.TIJD), 'u m') },
				];
		}
	}

	/**
	 * Eerst geoorloofd, daarna ongeoorloofd.
	 */
	partitionBarData(rowRoot: Level<AfwezigheidA, number[]>): Path<AfwezigheidA, number[]>[][] {
		return [orderBy(rowRoot.r, [(path) => last(path)!.k], ['desc'])];
	}

	createMeasureColumns(context: DashboardContext<AfwezigheidI, AfwezigheidA, AfwezigheidOverzichtComponent>): ColumnDef<DataRow<AfwezigheidA>>[] {
		switch (this.tijdseenheid()) {
			case Tijdseenheid.UREN:
				return [
					createMeasureColumn('Lesuren', att('aw_nr_lesuren_filtered')),
					createMeasureColumn('Leerlingen', att('nr_leerlingen_filtered'), { clickHandler: (path) => this.redirectToGroup(path, context) }),
					createMeasureColumn('Totaal lesuren', getTotaalLesuren),
				];
			case Tijdseenheid.DAGEN:
				return [
					createMeasureColumn('Lesdagen', att('aw_nr_lesdagen_filtered')),
					createMeasureColumn('Leerlingen', att('nr_leerlingen_filtered'), { clickHandler: (path) => this.redirectToGroup(path, context) }),
					createMeasureColumn('Totaal lesdagen', getTotaalLesdagen),
				];
			case Tijdseenheid.UREN_PERCENTAGE:
				return [
					createMeasureColumn('Lesuren (%)', getPercentageLesuren, {
						dataType: 'percentage',
						format: '1.2-2',
					}),
					createMeasureColumn('Lesuren', att('aw_nr_lesuren_filtered')),
					createMeasureColumn('Leerlingen', att('nr_leerlingen_filtered'), { clickHandler: (path) => this.redirectToGroup(path, context) }),
					createMeasureColumn('Totaal lesuren', getTotaalLesuren),
				];
			case Tijdseenheid.TIJD:
				return [
					createMeasureColumn('Lestijd', att('aw_nr_minuten_filtered'), { dataType: 'minutes', format: 'uur' }),
					createMeasureColumn('Leerlingen', att('nr_leerlingen_filtered'), { clickHandler: (path) => this.redirectToGroup(path, context) }),
					createMeasureColumn('Totaal lestijd', getTotaalMinuten, { dataType: 'minutes', format: 'uur' }),
				];
		}
	}

	isHistorieBatchVariant(): boolean {
		return this.variant() === DashboardVariant.HISTORIE && this.selectedGroups().length > 0;
	}

	createXAxis(context: DashboardContext<AfwezigheidI, AfwezigheidA, AfwezigheidOverzichtComponent>): Axis {
		const rootA = context.dataRoot!.a;
		switch (this.tijdseenheid()) {
			case Tijdseenheid.DAGEN:
				return { min: 0, max: rootA.max_dagen_filtered, ticks: [] };
			case Tijdseenheid.UREN:
				return { min: 0, max: rootA.max_uren_filtered, ticks: [] };
			case Tijdseenheid.UREN_PERCENTAGE: {
				const percFunc = percOfRow('aw_nr_lesuren_filtered', 'aw_nr_lesuren_x');
				const maxPerc = max(context.dataRoot!.r.map(percFunc)) || 0;
				return { min: 0, max: maxPerc, ticks: [] };
			}
			case Tijdseenheid.TIJD:
				return { min: 0, max: rootA.max_minuten_filtered, ticks: [] };
		}
	}

	getBarchartQty(path: Path<AfwezigheidA, number[]>) {
		switch (this.tijdseenheid()) {
			case Tijdseenheid.DAGEN:
				return att<'aw_nr_lesdagen_filtered', AfwezigheidA>('aw_nr_lesdagen_filtered')(path);
			case Tijdseenheid.UREN:
				return att<'aw_nr_lesuren_filtered', AfwezigheidA>('aw_nr_lesuren_filtered')(path);
			case Tijdseenheid.UREN_PERCENTAGE:
				return percOfRow('aw_nr_lesuren_filtered', 'aw_nr_lesuren_x')(path);
			case Tijdseenheid.TIJD:
				return att<'aw_nr_minuten_filtered', AfwezigheidA>('aw_nr_minuten_filtered')(path);
		}
	}

	get intervalSubgroups(): AttrPath[] {
		switch (this.interval()) {
			case Interval.MAAND:
				return [
					['aw_d_datum', 'per_nm_schooljaar'],
					['aw_d_datum', 'per_nr_maand'],
				];
			case Interval.SCHOOLJAAR:
				return [['aw_d_datum', 'per_nm_schooljaar']];
			case Interval.WEEK:
				return [
					['aw_d_datum', 'per_nm_schooljaar'],
					['aw_d_datum', 'per_nr_isoweek'],
				];
		}
	}

	get nrIntervalSubgroups(): 1 | 2 {
		return <1 | 2>this.intervalSubgroups.length;
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieGroups = memoize(AfwezigheidOverzichtComponent._getHistorieGroups, JSON.stringify);

	private static _getHistorieGroups(selectedGroups: AttrPath[]) {
		return selectedGroups.slice(0, -1);
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieSubgroups = memoize(AfwezigheidOverzichtComponent._getHistorieSubgroups, JSON.stringify);

	private static _getHistorieSubgroups([selectedGroups, intervalSubgroups, fixedSubgroups]: [AttrPath[], AttrPath[], AttrPath[]]): AttrPath[] {
		return [...selectedGroups.slice(-1), ...intervalSubgroups, ...fixedSubgroups];
	}

	createLinkData(
		path: Path<AfwezigheidA, number[]>,
		context: DashboardContext<AfwezigheidI, AfwezigheidA, AfwezigheidOverzichtComponent>
	): Partial<LinkData> {
		const alleLeerlingenLinkData = super.createLinkData(path, context);
		const filterExpression = <CompoundFilterExpression>alleLeerlingenLinkData.filter;
		const absenteLeerlingenFilter = new CompoundFilterExpression([...filterExpression.filters, new BasicFilterExpression(['aw_is_absent'], 1)]);
		return {
			...alleLeerlingenLinkData,
			filter: absenteLeerlingenFilter,
			dashboard: '/details/leerling/afwezigheid',
			dataProvider: 'aanwezigheid',
		};
	}

	factTable = FactTable.aanwezigheid;

	getData(options: { g: AttrPath[]; f?: FilterExpression }): Observable<DataResponse<number[]>> {
		const isActueel = this.variant() === DashboardVariant.ACTUEEL;
		const totaalLevel = this.getTotaalLevel();
		const redenLevel = options.g.map((path) => path.join('.')).indexOf('aw_nm_abs_reden');
		const xa: number[][] = [[...(redenLevel >= 0 ? [redenLevel] : []), totaalLevel]];

		const eenheidMeasure = this.tijdseenheid() === Tijdseenheid.DAGEN ? AanwezigheidMeasure.LESDAGEN : AanwezigheidMeasure.LESUREN;
		const m = isActueel ? [eenheidMeasure, AanwezigheidMeasure.LEERLINGEN] : [eenheidMeasure];

		// Verplaats aw_is_abs_geoorloofd en aw_nm_abs_reden naar "having" zodat we de totalen berekenen hierop te filteren.
		const { f, having } = this.dataService.moveToHaving([['aw_is_abs_geoorloofd'], ['aw_nm_abs_reden']], options);
		const dataOptions = {
			...options,
			r: [0, totaalLevel],
			m,
			f,
			having: having ?? new BasicFilterExpression(['aw_is_abs_geoorloofd'], null, '<>'),
			xa,
		};
		return this.dataService.getAanwezigheidData(dataOptions);
	}

	getTotaalLevel() {
		const isActueel = this.variant() === DashboardVariant.ACTUEEL;
		return this.selectedGroups().length + (isActueel ? 0 : this.intervalSubgroups.length) + (this.fixedSubgroups.length - 1);
	}

	getExportData(options: ExportDataOptions) {
		return this.dataService.getAanwezigheidExportData({
			...options,
			f: new CompoundFilterExpression([options.f, new BasicFilterExpression(['aw_is_absent'], 1)]),
		});
	}

	protected readonly Tijdseenheid = Tijdseenheid;
	protected readonly Interval = Interval;
}

function getTotaalLesuren(row: Path<AfwezigheidA, unknown>) {
	return row.length == 1 ? getLeafA(row).aw_nr_lesuren : getLeafA(row).aw_nr_lesuren_x;
}

function getTotaalLesdagen(row: Path<AfwezigheidA, unknown>) {
	return row.length == 1 ? getLeafA(row).aw_nr_lesdagen : getLeafA(row).aw_nr_lesdagen_x;
}

function getTotaalMinuten(row: Path<AfwezigheidA, unknown>) {
	return row.length == 1 ? getLeafA(row).aw_nr_minuten : getLeafA(row).aw_nr_minuten_x;
}

function getPercentageLesuren(row: Path<AfwezigheidA, unknown>) {
	const rowLesuren = getLeafA(row).aw_nr_lesuren_filtered;
	const totaal = getTotaalLesuren(row);
	return deelVeilig(rowLesuren, totaal);
}
