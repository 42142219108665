import { Component } from '@angular/core';
import { TableCellComponent } from '../../table/table.model';
import { TooltipDirective, TooltipType } from '@cumlaude/shared-components-overlays';

@Component({
	selector: 'app-multiline-cell',
	templateUrl: './multiline-cell.component.html',
	styleUrls: ['./multiline-cell.component.scss'],
	standalone: true,
	imports: [TooltipDirective],
})
export class MultilineCellComponent implements TableCellComponent<string[]> {
	data!: string[];
	className?: string;
	tooltip?: TooltipType;

	getExportValue(data: string[]) {
		return data.join('\n');
	}
}
