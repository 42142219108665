import { Component } from '@angular/core';
import { TableCellComponent } from '../../table/table.model';
import { TooltipDirective } from '@cumlaude/shared-components-overlays';
import { isNil } from 'lodash-es';

type LabelWithTooltip = { text: string; tooltip?: string; icon?: string };

export type LabelCellData = string | null | LabelWithTooltip;

@Component({
	selector: 'app-label-cell',
	templateUrl: './label-cell.component.html',
	styleUrls: ['./label-cell.component.scss'],
	standalone: true,
	imports: [TooltipDirective],
})
export class LabelCellComponent implements TableCellComponent<LabelCellData> {
	data!: LabelCellData;
	className?: string;

	getText(data: LabelCellData): string {
		if (isNil(data)) return '';
		return typeof data === 'string' ? data : data.text;
	}

	getLabelWithTooltip(data: LabelCellData): LabelWithTooltip | undefined {
		if (isNil(data)) return { text: '' };
		return typeof data === 'string' ? undefined : data;
	}

	getExportValue(data: LabelCellData) {
		return this.getText(data);
	}
}
