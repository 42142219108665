import { Directive, OnInit } from '@angular/core';
import { UitstroomtypeVso } from '@cumlaude/metadata';
import { flatten, last, memoize, partition } from 'lodash-es';
import { Observable } from 'rxjs';
import { countRecords } from '../../services/aggregation';
import { Level, Path } from '../../services/data-tree';
import {
	Attr,
	AttrPath,
	BasicFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	ExportDataOptions,
	FilterExpression,
} from '../../services/data.service';
import { FilterName } from '../../services/filter-config';
import { FilterService } from '../../services/filter.service';
import { generateCssClassForString } from '@cumlaude/shared-utils';
import { att, percOfRow } from '../../services/measures';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { BarInfo } from '../../services/stacked-bars';
import { createMeasureColumn, DataRow } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { ColumnDef, TableModel } from '../../shared/components/table/table/table.model';
import { BarchartTableConfig } from '../../shared/dashboard/barchart-table/barchart-table-config';
import { Attributes, LinkData } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { FactTable } from '../../services/exportable';
import { ToastrService } from 'ngx-toastr';
import { UrlService } from '../../services/url.service';
import { Router } from '@angular/router';
import { PartitionMeasure } from '../../shared/dashboard/vbarchart-table/vbarchart-table.component';
import { DashboardVariant } from '../../services/weergave-opties';
import { prestatieanalyseDsFilterExcludes } from '../../services/exportable-map';
import { TooltipElement } from '@cumlaude/shared-components-overlays';

export interface UitstroomVsoA extends Attributes {
	positief: number;
	onbekend: number;
}

export const uitstroomVsoActueelFilters: FilterName[] = [
	'x_prestatieanalyse_ds_schooljaar',
	'ds_fk_br_vest_van.br_co_brin',
	'ds_nm_prestatieanalyse_vso_uitzondering',
];

@Directive()
export abstract class UitstroomVsoComponent<I extends Attributes = Attributes> extends BarchartTableConfig<I, UitstroomVsoA> implements OnInit {
	protected abstract attr: keyof I & Attr;
	protected abstract positiefWaarde: string;
	protected abstract className: string;
	protected abstract positiefLabel: string;
	protected abstract getUitstroomtype(): UitstroomtypeVso;

	defaultGroups: AttrPath[] = [
		['ds_fk_ilt_van', 'ilt_nm_niveau'], //
		['ds_nr_leerjaar_van'],
	];

	selectedGroups: AttrPath[] = this.defaultGroups;

	availableGroups: AttrPath[] = [
		['ds_nm_klas_van'],
		['ds_nr_leerjaar_van'],
		['ds_fk_ilt_van', 'ilt_nm_niveau'],
		['ds_nm_opleiding_van'],
		['ds_nm_uitstroomprofiel_vso_van'],
	];

	actueelFilters: FilterName[] = uitstroomVsoActueelFilters;

	historieFilters: FilterName[] = [
		'x_doorstroom_schooljaar_historie', //
		'x_doorstroom_multiselect_schooljaar',
		...this.actueelFilters.slice(1),
	];

	filterExpressions?: FilterExpression[];

	permanentFilterExpressions: FilterExpression[] = [
		new BasicFilterExpression(['ds_is_plaatsing_opeenvolgend'], 1), // Voorkom dubbelingen maar sta wel tussentijdse in/uitstroom toe
		new BasicFilterExpression(['ds_nm_uitstroomtype_vso'], this.getUitstroomtype()),
		new BasicFilterExpression(['ds_fk_br_vest_van', 'br_is_vso'], 1),
	];

	variant!: DashboardVariant;

	dashboardName!: string;

	constructor(
		protected dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected toastr: ToastrService,
		protected urlService: UrlService,
		protected router: Router
	) {
		super(filterService, toastr);
	}

	ngOnInit() {
		this.subscribeToQueryParams();
	}

	subscribeToQueryParams() {
		this.subscriptions.push(
			this.qp.observe('variant').subscribe((variant) => (this.variant = variant)),
			this.qp.observe_g().subscribe((groups) => (this.selectedGroups = groups ?? this.defaultGroups)),
			this.urlService.routeData$.subscribe((routeData) => (this.dashboardName = routeData.title))
		);
	}

	factTable = FactTable.doorstroom;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		return this.dataService.getDoorstroomData(options);
	}

	getExportData(options: ExportDataOptions) {
		return this.dataService.getDoorstroomExportData(options);
	}

	protected singleAggregators = {
		positief: countRecords((attrs: I) => attrs[this.attr] === this.positiefWaarde),
		onbekend: countRecords((attrs: I) => attrs[this.attr] === null),
	};

	partitionBarData(rowRoot: Level<UitstroomVsoA, number[]>): Path<UitstroomVsoA, number[]>[][] {
		const alle = flatten(rowRoot.c.map((c) => c.r));
		return partition(alle, (c) => last(c)!.k === this.positiefWaarde).filter((p) => p.length > 0);
	}

	makeBar(attrs: I, path: Path<UitstroomVsoA, number[]>, context: DashboardContext<I, UitstroomVsoA, UitstroomVsoComponent<I>>): BarInfo {
		const tooltipElements: TooltipElement[] = [
			{ label: this.dashboardName, value: `${this.displayService.display(attrs[this.attr])}` },
			{ label: `Leerlingen:`, value: `${attrs.count_records}` },
		];

		return {
			...super.makeBar(attrs, path, context),
			className: generateCssClassForString(<string>attrs[this.attr] ?? 'onbekend'),
			tooltip: tooltipElements,
		};
	}

	createLinkData(path: Path<unknown, number[]>, context: DashboardContext<I, UitstroomVsoA, UitstroomVsoComponent<I>>): Partial<LinkData> {
		return {
			dashboard: '/details/leerling/doorstroom',
			dataProvider: 'doorstroom',
			...super.createLinkData(path, context),
		};
	}

	createMeasureColumns(context: DashboardContext<I, UitstroomVsoA, UitstroomVsoComponent<I>>): ColumnDef<DataRow<UitstroomVsoA>>[] {
		if (this.variant === 'Historie') return [];

		return [
			createMeasureColumn('Percentage', percOfRow('positief', 'count_records'), { context, dataType: 'percentage' }),
			createMeasureColumn(this.positiefLabel, att('positief'), { context }),
			createMeasureColumn('Onbekend', att('onbekend'), { context, clickHandler: (path) => this.handleUitzonderingRedirect(path) }),
			createMeasureColumn('Leerlingen', att('count_records'), { context, clickHandler: (path) => this.redirectToGroup(path, context) }),
		];
	}

	isHistorieBatchVariant(): boolean {
		return this.variant === DashboardVariant.HISTORIE && this.selectedGroups.length > 0;
	}

	private handleUitzonderingRedirect(path: Path<UitstroomVsoA, number[]>) {
		this.addUitzonderingFilters(path);

		this.router.navigate([this.getUitzonderingUrl()], {
			queryParams: this.getUitzonderingQueryParams(),
		});
	}

	protected abstract getUitzonderingUrl(): string;

	getUitzonderingQueryParams() {
		return {
			from: this.urlService.getFrom(),
		};
	}

	addUitzonderingFilters(path: Path<UitstroomVsoA, number[]>) {
		const indexNiveau = this.selectedGroups.findIndex((value) => value.join('.') === 'ds_fk_ilt_van.ilt_nm_niveau');
		if (indexNiveau > -1) this.filterService.setFilterInput('ds_fk_ilt_van.ilt_nm_niveau', [path[indexNiveau + 1].k]);

		const indexLeerjaar = this.selectedGroups.findIndex((value) => value.join('.') === 'ds_nr_leerjaar_van');
		if (indexLeerjaar > -1) this.filterService.setFilterInput('ds_nr_leerjaar_van', [Number(path[indexLeerjaar + 1].k)]);
	}

	filterExcludes(): FilterName[] {
		return prestatieanalyseDsFilterExcludes;
	}

	partitionMeasure: PartitionMeasure<UitstroomVsoA> = { type: 'percentage', getValue: percOfRow('positief', 'count_records') };

	// memoize, otherwise new array keeps triggering change detection
	getHistorieGroups = memoize(UitstroomVsoComponent._getHistorieGroups, JSON.stringify);

	private static _getHistorieGroups(selectedGroups: AttrPath[]) {
		return selectedGroups.slice(0, -1);
	}

	// memoize, otherwise new array keeps triggering change detection
	getHistorieSubgroups = memoize(UitstroomVsoComponent._getHistorieSubgroups, JSON.stringify);

	private static _getHistorieSubgroups([selectedGroups, fixedSubgroups]: [AttrPath[], AttrPath[]]): AttrPath[] {
		return [...selectedGroups.slice(-1), ['ds_nm_schooljaar_van'], ...fixedSubgroups];
	}

	enrichTableModel(_context: DashboardContext<I, UitstroomVsoA, UitstroomVsoComponent<I>>, tableModel: TableModel<DataRow<UitstroomVsoA>>) {
		tableModel.showFooters = this.variant === 'Actueel';
	}
}
