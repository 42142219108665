export type ForeignKeyMetadata = {
    type: string;
    labelSuffix: string | undefined;
};

export type FieldMetadata = {
    description: string | undefined;
    examples: string[] | undefined;
    label: string | undefined;
    searchKeys: string[] | undefined;
    filter: boolean;
    exportable: boolean;
};

export type CumLaudeTableMetadata = {
    [key: string]: ForeignKeyMetadata | FieldMetadata;
};

export type CumLaudeTablesMetadata = {
    [key: string]: CumLaudeTableMetadata;
};

const fac_aw_aanwezigheid: CumLaudeTableMetadata = {
    aw_abb_abs_reden: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_abb_vak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_abb_vak_land: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_co_vak_land: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_d_datum: {
        type: "dim_per_periode",
        labelSuffix: undefined
    },
    aw_fk_br_inst: {
        type: "dim_br_brin",
        labelSuffix: undefined
    },
    aw_fk_br_vest: {
        type: "dim_br_brin",
        labelSuffix: undefined
    },
    aw_fk_ilt_volgend_sj: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    aw_fk_ilt_vorig_sj: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    aw_fk_ilt: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    aw_fk_inst: {
        type: "instelling",
        labelSuffix: undefined
    },
    aw_fk_lb_volgend_sj: {
        type: "fac_lb_loopbaan",
        labelSuffix: undefined
    },
    aw_fk_lb_vorig_sj: {
        type: "fac_lb_loopbaan",
        labelSuffix: undefined
    },
    aw_fk_lb: {
        type: "fac_lb_loopbaan",
        labelSuffix: undefined
    },
    aw_fk_lg: {
        type: "dim_lg_lesgroep",
        labelSuffix: undefined
    },
    aw_fk_ll: {
        type: "dim_ll_leerling",
        labelSuffix: undefined
    },
    aw_fk_mw_mentor_indiv: {
        type: "dim_mw_medewerker",
        labelSuffix: undefined
    },
    aw_fk_mw_mentor1: {
        type: "dim_mw_medewerker",
        labelSuffix: undefined
    },
    aw_fk_mw_mentor2: {
        type: "dim_mw_medewerker",
        labelSuffix: undefined
    },
    aw_fk_vk: {
        type: "dim_vk_vak",
        labelSuffix: undefined
    },
    aw_fk_vs: {
        type: "dim_vs_vestiging",
        labelSuffix: undefined
    },
    aw_fk_vs_bekostiging: {
        type: "dim_vs_vestiging",
        labelSuffix: undefined
    },
    aw_fun_is_plaatsing_peildatum_1april: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_is_plaatsing_peildatum_1feb: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_is_plaatsing_peildatum_1jan: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_is_plaatsing_peildatum_1juli: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_is_plaatsing_peildatum_1okt: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_nationaliteit: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_plaatsing_advies_score: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_plaatsing_advies_verschil: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_plaatsing_lj3_advies_score: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_plaatsing_lj3_advies_verschil: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fks_mw: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_is_abs_geoorloofd: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_is_absent: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nm_abs_reden: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nm_abs_soort: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nm_klas: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nm_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nm_vak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nm_vak_land: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nr_leerjaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nr_leerling: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nr_lesuur: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nr_minuten: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_ts_begin: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_ts_eind: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const fac_aw_aanwezigheid_dag: CumLaudeTableMetadata = {
    aw_d_datum: {
        type: "dim_per_periode",
        labelSuffix: undefined
    },
    aw_fk_br_vest: {
        type: "dim_br_brin",
        labelSuffix: undefined
    },
    aw_fk_ilt_volgend_sj: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    aw_fk_ilt_vorig_sj: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    aw_fk_ilt: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    aw_fk_inst: {
        type: "instelling",
        labelSuffix: undefined
    },
    aw_fk_lb_volgend_sj: {
        type: "fac_lb_loopbaan",
        labelSuffix: undefined
    },
    aw_fk_lb_vorig_sj: {
        type: "fac_lb_loopbaan",
        labelSuffix: undefined
    },
    aw_fk_lb: {
        type: "fac_lb_loopbaan",
        labelSuffix: undefined
    },
    aw_fk_ll: {
        type: "dim_ll_leerling",
        labelSuffix: undefined
    },
    aw_fk_vs: {
        type: "dim_vs_vestiging",
        labelSuffix: undefined
    },
    aw_fk_vs_bekostiging: {
        type: "dim_vs_vestiging",
        labelSuffix: undefined
    },
    aw_fun_is_plaatsing_peildatum_1april: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_is_plaatsing_peildatum_1feb: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_is_plaatsing_peildatum_1jan: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_is_plaatsing_peildatum_1juli: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_is_plaatsing_peildatum_1okt: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_nationaliteit: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_plaatsing_advies_score: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_plaatsing_advies_verschil: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_plaatsing_lj3_advies_score: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_plaatsing_lj3_advies_verschil: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_is_abs_geoorloofd: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_is_absent: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_is_presentieregistratie_verplicht: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_is_presentieregistratie_verwerkt: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nm_abs_reden: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nm_abs_soort: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nm_klas: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nm_opleiding: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nm_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nm_vestiging: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nr_leerjaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nr_leerling: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const fac_aw_aanwezigheid_maand: CumLaudeTableMetadata = {
    aw_fk_br_vest: {
        type: "dim_br_brin",
        labelSuffix: undefined
    },
    aw_fk_ilt_volgend_sj: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    aw_fk_ilt_vorig_sj: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    aw_fk_ilt: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    aw_fk_inst: {
        type: "instelling",
        labelSuffix: undefined
    },
    aw_fk_lb_volgend_sj: {
        type: "fac_lb_loopbaan",
        labelSuffix: undefined
    },
    aw_fk_lb_vorig_sj: {
        type: "fac_lb_loopbaan",
        labelSuffix: undefined
    },
    aw_fk_lb: {
        type: "fac_lb_loopbaan",
        labelSuffix: undefined
    },
    aw_fk_ll: {
        type: "dim_ll_leerling",
        labelSuffix: undefined
    },
    aw_fk_vs: {
        type: "dim_vs_vestiging",
        labelSuffix: undefined
    },
    aw_fks_mw: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_is_plaatsing_peildatum_1april: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_is_plaatsing_peildatum_1feb: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_is_plaatsing_peildatum_1jan: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_is_plaatsing_peildatum_1juli: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_is_plaatsing_peildatum_1okt: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_nationaliteit: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_plaatsing_advies_score: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_plaatsing_advies_verschil: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_plaatsing_lj3_advies_score: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_fun_plaatsing_lj3_advies_verschil: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_is_abs_geoorloofd: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_is_absent: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nm_abs_reden: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nm_klas: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nm_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nm_vak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nr_leerjaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nr_leerling: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nr_maand: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const fac_aw_aanwezigheid_schooljaar: CumLaudeTableMetadata = {
    aw_fk_inst: {
        type: "instelling",
        labelSuffix: undefined
    },
    aw_fk_vs: {
        type: "dim_vs_vestiging",
        labelSuffix: undefined
    },
    aw_nm_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nm_vak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    aw_nr_leerling: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const fac_bv_basisvaardigheden: CumLaudeTableMetadata = {
    bv_d_afname: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bv_fk_ilt_volgend_sj: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    bv_fk_ilt_vorig_sj: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    bv_fk_ilt: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    bv_fk_inst: {
        type: "instelling",
        labelSuffix: undefined
    },
    bv_fk_lb_volgend_sj: {
        type: "fac_lb_loopbaan",
        labelSuffix: undefined
    },
    bv_fk_lb_vorig_sj: {
        type: "fac_lb_loopbaan",
        labelSuffix: undefined
    },
    bv_fk_lb: {
        type: "fac_lb_loopbaan",
        labelSuffix: undefined
    },
    bv_fk_ll: {
        type: "dim_ll_leerling",
        labelSuffix: undefined
    },
    bv_fk_vs: {
        type: "dim_vs_vestiging",
        labelSuffix: undefined
    },
    bv_fun_basisschooladvies_duo: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bv_fun_is_plaatsing_peildatum_1april: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bv_fun_is_plaatsing_peildatum_1feb: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bv_fun_is_plaatsing_peildatum_1jan: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bv_fun_is_plaatsing_peildatum_1juli: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bv_fun_is_plaatsing_peildatum_1okt: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bv_fun_nationaliteit: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bv_fun_referentieniveau_bin: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bv_fun_sortkey_afname: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bv_id_vestiging: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bv_is_eindtoets_po: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bv_is_laatste_van_sj: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bv_nm_aanbieder: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bv_nm_leerfase: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bv_nm_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bv_nm_toets: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bv_nm_toetsniveau: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bv_nm_toetsmoment: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bv_nm_referentieniveau: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bv_nm_vaardigheid: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bv_nr_leerjaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bv_nr_leerling: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bv_nr_referentieniveau: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bv_nr_verbetering: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bv_pk_key: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const fac_ekc_examenkandidaten_en_cijfers: CumLaudeTableMetadata = {
    ekc_fk_br_vest: {
        type: "dim_br_brin",
        labelSuffix: undefined
    },
    ekc_fk_br_inst: {
        type: "dim_br_brin",
        labelSuffix: undefined
    },
    ekc_fk_vk: {
        type: "dim_vk_vak",
        labelSuffix: undefined
    },
    ekc_nm_bron: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ekc_nm_cijfertype: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ekc_nm_niveau: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ekc_nm_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ekc_nr_gem_cijfer: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ekc_nr_q_cijfers: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const fac_cf_cijfer: CumLaudeTableMetadata = {
    cf_abb_adviescategorie: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_abb_kolomkop: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_abb_label: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_abb_onderwijssoort_vak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_abb_vak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_co_kolom: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_d_cijfer: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_des_kolom: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_fk_br_vest: {
        type: "dim_br_brin",
        labelSuffix: undefined
    },
    cf_fk_clc: {
        type: "dim_clc_cijfer_label_conversie",
        labelSuffix: undefined
    },
    cf_fk_ilt: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    cf_fk_ilt_volgend_sj: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    cf_fk_ilt_vorig_sj: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    cf_fk_inst: {
        type: "instelling",
        labelSuffix: undefined
    },
    cf_fk_lb: {
        type: "fac_lb_loopbaan",
        labelSuffix: undefined
    },
    cf_fk_lb_volgend_sj: {
        type: "fac_lb_loopbaan",
        labelSuffix: undefined
    },
    cf_fk_lb_vorig_sj: {
        type: "fac_lb_loopbaan",
        labelSuffix: undefined
    },
    cf_fk_ll: {
        type: "dim_ll_leerling",
        labelSuffix: undefined
    },
    cf_fk_lg: {
        type: "dim_lg_lesgroep",
        labelSuffix: undefined
    },
    cf_fk_mw_mentor1: {
        type: "dim_mw_medewerker",
        labelSuffix: undefined
    },
    cf_fk_mw_mentor2: {
        type: "dim_mw_medewerker",
        labelSuffix: undefined
    },
    cf_fk_mw_mentor_indiv: {
        type: "dim_mw_medewerker",
        labelSuffix: undefined
    },
    cf_fk_or_exccl: {
        type: "fac_or_onderwijsresultaten",
        labelSuffix: undefined
    },
    cf_fk_or_cvcl: {
        type: "fac_or_onderwijsresultaten",
        labelSuffix: undefined
    },
    cf_fk_vk: {
        type: "dim_vk_vak",
        labelSuffix: undefined
    },
    cf_fk_vs: {
        type: "dim_vs_vestiging",
        labelSuffix: undefined
    },
    cf_fk_vs_bekostiging: {
        type: "dim_vs_vestiging",
        labelSuffix: undefined
    },
    cf_fkd_aw_schooljaar: {
        type: "fac_aw_aanwezigheid_schooljaar",
        labelSuffix: undefined
    },
    cf_fks_mw: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_fun_basisschooladvies_duo: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_fun_cijferverschil_se_ce: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_fun_cijferverschil_se_ce_afgerond: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_fun_is_plaatsing_actueel: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_fun_is_plaatsing_peildatum_1april: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_fun_is_plaatsing_peildatum_1feb: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_fun_is_plaatsing_peildatum_1jan: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_fun_is_plaatsing_peildatum_1juli: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_fun_is_plaatsing_peildatum_1okt: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_fun_kolomgroep: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_fun_leerjaar_niveau_lesgroep: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_fun_nationaliteit: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_fun_nm_vak_uni: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_fun_nr_aantal_docenten: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_fun_nr_volgnr_vak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_fun_periode: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_fun_plaatsing_advies_score: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_fun_plaatsing_advies_verschil: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_fun_plaatsing_lj3_advies_score: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_fun_plaatsing_lj3_advies_verschil: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_fun_schooljaar_leerfase: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_fun_sum_tekortpunten: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_fun_toets_rank: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_fun_vest_brin: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_is_actueel: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_is_alternatievenormering: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_is_combinatiecijfer: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_is_combinatiecijfervak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_is_diplomavak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_is_examendossier: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_is_examenkandidaat: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_is_hoogste_ce: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_is_hoogste_eind: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_is_laatste_gemiddelde: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_is_laatste_rapportcijfer: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_is_oke: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_is_telt_mee_voor_slagen: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_is_voldoende: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_is_voortgangsdossier: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_map_examenstatus: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_map_examenstatus_met_prognose: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_map_idu: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nm_adviescategorie: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nm_bijzonderheid: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nm_bron: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nm_eerder_geexamineerd: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nm_excijf_uitzondering: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nm_klas: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nm_kolomtype: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nm_label: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nm_leerling: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nm_lesgroep: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nm_lesgroep_docenten: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nm_lichting: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nm_lichting_vak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nm_normering: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nm_opleiding: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nm_periode: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nm_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nm_soort_vakpositie: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nm_vak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nm_vestiging: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nr_aantal_toetsen: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nr_cijfer: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nr_cijfer_afgerond: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nr_cijfer_ce: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nr_cijfer_eind: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nr_cijfer_vorig_sj: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nr_decimalen: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nr_gem_cijfer_andere_vakken: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nr_kolom: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nr_leerjaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nr_leerjaar_vak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nr_leerling: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nr_leerlingen: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nr_periode: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nr_sectiegemiddelde: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nr_tekortpunten: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nr_volgnr: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nr_volgnr_vak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    cf_nr_weging: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const fac_ck_cijferkolommen: CumLaudeTableMetadata = {
    ck_abb_kolomkop: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ck_co_kolom: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ck_fk_ilt: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    ck_fk_inst: {
        type: "instelling",
        labelSuffix: undefined
    },
    ck_is_any_laatste_gemiddelde: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ck_nm_klas: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ck_nm_opleiding: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ck_nm_periode: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ck_nm_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ck_nm_vestiging: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ck_nm_vak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ck_nr_kolom: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ck_nr_laatste_gemiddelde: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ck_nr_leerjaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ck_nr_periode: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ck_nr_resultaatkolommen: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const fac_ds_doorstroom: CumLaudeTableMetadata = {
    ds_co_brin_svh: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_co_brin_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_d_geldig_tot: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_d_geldig_va: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_d_plaatsing_tm: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_d_plaatsing_va: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_d_plaatsing_va_volgend: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fk_br_vest_naar: {
        type: "dim_br_brin",
        labelSuffix: undefined
    },
    ds_fk_br_vest_van: {
        type: "dim_br_brin",
        labelSuffix: undefined
    },
    ds_fk_ilt_naar: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    ds_fk_ilt_van: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    ds_fk_ilt_vorig_sj: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    ds_fk_inst: {
        type: "instelling",
        labelSuffix: undefined
    },
    ds_fk_lb_naar: {
        type: "fac_lb_loopbaan",
        labelSuffix: undefined
    },
    ds_fk_lb_van: {
        type: "fac_lb_loopbaan",
        labelSuffix: undefined
    },
    ds_fk_lb_vorig_sj: {
        type: "fac_lb_loopbaan",
        labelSuffix: undefined
    },
    ds_fk_ll: {
        type: "dim_ll_leerling",
        labelSuffix: undefined
    },
    ds_fk_mw_mentor1: {
        type: "dim_mw_medewerker",
        labelSuffix: undefined
    },
    ds_fk_mw_mentor2: {
        type: "dim_mw_medewerker",
        labelSuffix: undefined
    },
    ds_fk_mw_mentor_indiv: {
        type: "dim_mw_medewerker",
        labelSuffix: undefined
    },
    ds_fk_or_bbscl: {
        type: "fac_or_onderwijsresultaten",
        labelSuffix: undefined
    },
    ds_fk_or_obscl: {
        type: "fac_or_onderwijsresultaten",
        labelSuffix: undefined
    },
    ds_fk_or_opcl: {
        type: "fac_or_onderwijsresultaten",
        labelSuffix: undefined
    },
    ds_fk_pc_leerling_naar: {
        type: "dim_pc_postcode",
        labelSuffix: undefined
    },
    ds_fk_pc_leerling_van: {
        type: "dim_pc_postcode",
        labelSuffix: undefined
    },
    ds_fk_vs_van: {
        type: "dim_vs_vestiging",
        labelSuffix: undefined
    },
    ds_fk_vs_naar: {
        type: "dim_vs_vestiging",
        labelSuffix: undefined
    },
    ds_fk_vs_bekostiging_van: {
        type: "dim_vs_vestiging",
        labelSuffix: undefined
    },
    ds_fk_vs_bekostiging_naar: {
        type: "dim_vs_vestiging",
        labelSuffix: undefined
    },
    ds_fun_basisschooladvies_duo: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_brinvest_overgang: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_cohortrendement_tot: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_cohortrendement_tot_doublure: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_cohortrendement_tot_niveau: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_cohortrendement_vanaf: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_cohortrendement_vanaf_doublure: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_cohortrendement_vanaf_niveau: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_cohortstatus_examenopstroom: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_cohortstatus_vanaf: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_doorstroom_ext: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_ergens_overgang: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_is_plaatsing_actueel: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_is_plaatsing_peildatum_1april: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_is_plaatsing_peildatum_1feb: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_is_plaatsing_peildatum_1jan: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_is_plaatsing_peildatum_1juli: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_is_plaatsing_peildatum_1okt: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_is_plaatsing_peildatum_actueel: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_leerfase_plus_vavo: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_nationaliteit: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_niveau_bbs_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_niveau_volgend_sj: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_onderwijstype: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_opstroomrendement: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_opstroomrendement_niveau: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_overgangmoment: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_plaatsing_advies_score: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_plaatsing_advies_verschil: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_plaatsing_lj3_advies_score: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_plaatsing_lj3_advies_verschil: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_uitstroom_extern_matrix: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_uitstroom_iq_status: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_verschil_basisschooladvies_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_verschil_basisschooladvies_van_order: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_verschil_basisschooladvies_duo_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_verschil_basisschooladvies_duo_van_order: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_verschil_basisschooladvies_herzien_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_verschil_basisschooladvies_herzien_van_order: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_verschil_basisschooladvies_naar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_verschil_basisschooladvies_naar_order: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_verschil_basisschooladvies_duo_naar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_verschil_basisschooladvies_duo_naar_order: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_verschil_basisschooladvies_herzien_naar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_fun_verschil_basisschooladvies_herzien_naar_order: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_1febr_plaatsing_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_1okt_plaatsing_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_afgewezen: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_afstroom: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_apcg_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_bevorderd: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_doorstroom: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_doublure: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_examenkandidaat_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_examenopstroom_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_examenprognose: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_geslaagd: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_instroom_extern_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_laatste_plaatsing_sj_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_nieuwkomer_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_oke: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_opstroom: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_plaatsing_opeenvolgend: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_prognose: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_relevante_doorstroom: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_succesvol: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_uitstroom: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_uitstroom_extern: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_uitstroom_geen_onderwijs: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_uitstroom_intern: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_uitstroom_ob: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_uitstroom_vavo: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_is_vest_relevant_sj_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_map_examenstatus: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_map_examenstatus_met_prognose: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_map_pro_volgend_sj: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_map_uitstroom_hoger_onderwijs_geen_uitslag: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_map_uitstroom_speciaal_onderwijs: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_bbs_uitzondering_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_bekostigingstype_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_bron: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_einduitstroom_vso: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_einduitstroom_vso_uitzondering: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_idu: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_instroom_in_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_instroom_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_instroommoment_in_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_instroommoment_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_instroomtype_in_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_instroomtype_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_klas_naar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_klas_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_obs_uitzondering_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_opleiding_bekostiging_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_opleiding_naar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_opleiding_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_op_status_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_op_uitzondering_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_plaatsing_uitzondering: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_prestatieanalyse_vso_uitzondering: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_schooljaar_naar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_schooljaar_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_status: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_tussentijdse_uitstroom_vso: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_tussentijdse_uitstroom_vso_uitzondering: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_uitstroom: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_uitstroom_in_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_uitstroommoment: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_uitstroommoment_in_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_uitstroomprofiel_vso_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_uitstroomtype: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_uitstroomtype_in_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_uitstroomtype_vso: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_vestiging_naar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nm_vestiging_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nr_leerjaar_naar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nr_leerjaar_van: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nr_leerling: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nr_prognose: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nr_versie: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_nr_weging: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ds_pk_key: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const fac_lb_loopbaan: CumLaudeTableMetadata = {
    lb_abb_leerfase: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_co_brin: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_co_brin_svh: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_co_brin_vestiging: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_co_brinvest_volgend_pl: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_co_niveau_volgend_sj: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_d_1okt: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_d_examen: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_d_plaatsing_va: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_d_plaatsing_tm: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_d_inschrijving: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_d_uitschrijving: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_des_plaatsingstatus: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_fk_br_vest: {
        type: "dim_br_brin",
        labelSuffix: undefined
    },
    lb_fk_br_vest_volgend_pl: {
        type: "dim_br_brin",
        labelSuffix: undefined
    },
    lb_fk_ilt: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    lb_fk_ilt_volgend_pl: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    lb_fk_ilt_volgend_sj: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    lb_fk_ll: {
        type: "dim_ll_leerling",
        labelSuffix: undefined
    },
    lb_fk_or_bbscl: {
        type: "fac_or_onderwijsresultaten",
        labelSuffix: undefined
    },
    lb_fk_or_obscl: {
        type: "fac_or_onderwijsresultaten",
        labelSuffix: undefined
    },
    lb_fk_or_opcl: {
        type: "fac_or_onderwijsresultaten",
        labelSuffix: undefined
    },
    lb_fk_pc_leerling: {
        type: "dim_pc_postcode",
        labelSuffix: undefined
    },
    lb_fk_vs: {
        type: "dim_vs_vestiging",
        labelSuffix: undefined
    },
    lb_fk_vs_bekostiging: {
        type: "dim_vs_vestiging",
        labelSuffix: undefined
    },
    lb_fk_vs_volgend_pl: {
        type: "dim_vs_vestiging",
        labelSuffix: undefined
    },
    lb_id_vestiging: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_is_1febr_plaatsing: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_is_1okt_plaatsing: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_is_afgewezen: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_is_apcg: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_is_bekostigd: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_is_bevorderd: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_is_doublure: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_is_examenkandidaat: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_is_examenopleiding: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_is_examenopstroom: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_is_geemigreerd: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_is_geldig_bbs: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_is_geldig_obs: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_is_geldig_op: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_is_geslaagd: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_is_instroom_extern: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_is_instroom_intern: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_is_laatste_plaatsing_sj: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_is_nieuwkomer: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_is_pl_voorlopig: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_is_succesvol: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_is_uitstroom_extern: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_is_uitstroom_vavo: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_is_vest_relevant_sj: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_bbs_uitzondering: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_bekostigingstype: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_einduitstroom_vso: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_einduitstroom_vso_uitzondering: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_examenstatus: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_idu: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_instroom: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_instroom_in_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_instroommoment: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_instroommoment_in_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_instroomtype: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_instroomtype_in_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_klas: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_klas_volgend_pl: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_leerfase: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_lichting: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_niveau_volgend_sj: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_obs_uitzondering: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_onderwijssoort: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_opleiding: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_opleiding_bekostiging: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_op_uitzondering: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_prestatieanalyse_vso_uitzondering: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_schooljaar_volgend_pl: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_uitstroom: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_uitstroom_in_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_uitstroommoment: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_uitstroommoment_in_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_uitstroomprofiel_vso: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_uitstroomtype: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_uitstroomtype_in_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_uitstroomtype_vso: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_vestiging: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nm_vorige_vestiging: {
        description: "Bij een leerling die is gewisseld van vestiging: de laatste vorige vestiging die ongelijk is aan de vestiging van deze plaatsing.",
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nr_examenopstroom: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nr_leerjaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nr_leerjaar_svb: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nr_leerjaar_volgend_sj: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lb_nr_leerling: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const fac_lr_lesregistratie: CumLaudeTableMetadata = {
    lr_d_datum: {
        type: "dim_per_periode",
        labelSuffix: undefined
    },
    lr_des_lesregistratie: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lr_fk_br_vest: {
        type: "dim_br_brin",
        labelSuffix: undefined
    },
    lr_fk_ilt_volgend_sj: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    lr_fk_ilt_vorig_sj: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    lr_fk_ilt: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    lr_fk_inst: {
        type: "instelling",
        labelSuffix: undefined
    },
    lr_fk_lb_volgend_sj: {
        type: "fac_lb_loopbaan",
        labelSuffix: undefined
    },
    lr_fk_lb_vorig_sj: {
        type: "fac_lb_loopbaan",
        labelSuffix: undefined
    },
    lr_fk_lb: {
        type: "fac_lb_loopbaan",
        labelSuffix: undefined
    },
    lr_fk_lg: {
        type: "dim_lg_lesgroep",
        labelSuffix: undefined
    },
    lr_fk_ll: {
        type: "dim_ll_leerling",
        labelSuffix: undefined
    },
    lr_fk_vk: {
        type: "dim_vk_vak",
        labelSuffix: undefined
    },
    lr_fk_vs: {
        type: "dim_vs_vestiging",
        labelSuffix: undefined
    },
    lr_fk_vs_bekostiging: {
        type: "dim_vs_vestiging",
        labelSuffix: undefined
    },
    lr_fks_mw: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lr_fun_geoorloofd: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lr_fun_is_plaatsing_peildatum_1april: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lr_fun_is_plaatsing_peildatum_1feb: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lr_fun_is_plaatsing_peildatum_1jan: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lr_fun_is_plaatsing_peildatum_1juli: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lr_fun_is_plaatsing_peildatum_1okt: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lr_fun_nationaliteit: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lr_fun_nm_vak_uni: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lr_fun_plaatsing_advies_score: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lr_fun_plaatsing_advies_verschil: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lr_fun_plaatsing_lj3_advies_score: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lr_fun_plaatsing_lj3_advies_verschil: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lr_is_geoorloofd: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lr_nm_klas: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lr_nm_lesregistratie: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lr_nm_opleiding: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lr_nm_vak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lr_nr_leerjaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lr_nr_leerling: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lr_nr_leerlingen: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lr_nr_lesuur: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lr_ts_begin: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lr_ts_eind: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const fac_or_onderwijsresultaten: CumLaudeTableMetadata = {
    or_co_brin: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    or_co_brinvest: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    or_co_indicator: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    or_co_vestiging: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    or_d_geldig_tm: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    or_d_geldig_va: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    or_des_bron: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    or_fkd_no: {
        type: "dim_no_norm_onderwijsresultaat",
        labelSuffix: undefined
    },
    or_fkd_ov: {
        type: "dim_ov_onderwijspositie_vergelijkgroep",
        labelSuffix: undefined
    },
    or_fks_vs_bekostiging: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    or_fun_vest_brin: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    or_fun_vest_brin_filter: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    or_nm_bron: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    or_nm_indicator: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    or_nm_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    or_nm_soort_onderwijs: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    or_nm_vergelijkgroep: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    or_nr_jobrun: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    or_nr_peiljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    pj_nr_peiljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    or_nr_score: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    or_nr_versie: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    or_pk_key: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    or_q_apcg: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    or_q_instroom: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    or_q_leerlingen: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    or_q_opstroom: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    or_q_lwoo: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    or_q_vakken: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const fac_pv_prestatieanalyse_vso: CumLaudeTableMetadata = {
    pv_d_geldig_tm: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    pv_d_geldig_va: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    pv_co_brinvest: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    pv_fk_brinvest: {
        type: "dim_br_brin",
        labelSuffix: undefined
    },
    pv_fk_ppv: {
        type: "dim_ppv_percentiel_prestatie_vso",
        labelSuffix: undefined
    },
    pv_fk_vs_bekostiging: {
        type: "dim_vs_vestiging",
        labelSuffix: undefined
    },
    pv_fun_vest_brin: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    pv_nm_indicator: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    pv_nm_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    pv_nm_vergelijkgroep: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    pv_nr_score: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const fac_vkk_vakkeuze: CumLaudeTableMetadata = {
    vkk_abb_vak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_d_geldig_tot: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_d_geldig_va: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_fk_ilt_volgend_sj: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    vkk_fk_ilt_vorig_sj: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    vkk_fk_ilt: {
        type: "dim_ilt_opleidingscode",
        labelSuffix: undefined
    },
    vkk_fk_inst: {
        type: "instelling",
        labelSuffix: undefined
    },
    vkk_fk_lb_volgend_sj: {
        type: "fac_lb_loopbaan",
        labelSuffix: undefined
    },
    vkk_fk_lb_vorig_sj: {
        type: "fac_lb_loopbaan",
        labelSuffix: undefined
    },
    vkk_fk_lb: {
        type: "fac_lb_loopbaan",
        labelSuffix: undefined
    },
    vkk_fk_lg: {
        type: "dim_lg_lesgroep",
        labelSuffix: undefined
    },
    vkk_fk_ll: {
        type: "dim_ll_leerling",
        labelSuffix: undefined
    },
    vkk_fk_vk: {
        type: "dim_vk_vak",
        labelSuffix: undefined
    },
    vkk_fk_vs: {
        type: "dim_vs_vestiging",
        labelSuffix: undefined
    },
    vkk_fun_is_plaatsing_peildatum_1april: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_fun_is_plaatsing_peildatum_1feb: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_fun_is_plaatsing_peildatum_1jan: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_fun_is_plaatsing_peildatum_1juli: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_fun_is_plaatsing_peildatum_1okt: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_fun_is_vakkeuze_peildatum_1april: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_fun_is_vakkeuze_peildatum_1feb: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_fun_is_vakkeuze_peildatum_1jan: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_fun_is_vakkeuze_peildatum_1juli: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_fun_is_vakkeuze_peildatum_1okt: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_fun_is_vakkeuze_peildatum_actueel: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_fun_nationaliteit: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_fun_nm_vak_uni: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_is_vrijstelling: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_nm_bron: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_nm_lesgroep: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_nm_niveau_vak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_nm_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_nm_soort_vakpositie: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_nm_vak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_nr_jobrun: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_nr_leerjaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_nr_leerjaar_vak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_nr_leerling: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vkk_pk_key: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const dim_br_brin: CumLaudeTableMetadata = {
    br_co_brin: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    br_d_geldig_tot: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    br_d_geldig_va: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    br_is_vso: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    br_nm_brin_kort: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const dim_clc_cijfer_label_conversie: CumLaudeTableMetadata = {
    clc_abb_label: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const dim_ilt_opleidingscode: CumLaudeTableMetadata = {
    ilt_abb_profiel: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ilt_co_ilt: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ilt_co_niveau: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ilt_des_opleiding: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ilt_is_internationaal: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ilt_is_leerwerktraject: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ilt_is_lwoo: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ilt_is_vakmanschap: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ilt_is_vavo: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ilt_nm_leerweg: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ilt_nm_niveau: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ilt_nm_opleiding: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ilt_nm_profiel: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ilt_nr_examenjaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const dim_kl_klas: CumLaudeTableMetadata = {
    kl_d_geldig_va: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    kl_d_geldig_tot: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    kl_id_vestiging: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    kl_nm_klas: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    kl_nm_vestiging: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const dim_lg_lesgroep: CumLaudeTableMetadata = {
    lg_abb_docenten: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lg_abb_vak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lg_abb_vestiging: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lg_co_vak_land: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lg_d_schooljaar_tm: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lg_d_schooljaar_va: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lg_des_lesgroep: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lg_fk_vk: {
        type: "dim_vk_vak",
        labelSuffix: undefined
    },
    lg_fks_mw: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lg_nm_docenten: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lg_nm_lesgroep: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lg_nm_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lg_nm_vak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    lg_nm_vestiging: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const dim_ll_leerling: CumLaudeTableMetadata = {
    ll_co_basisschooladvies: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_co_niveau_lj3: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_d_geboortedatum: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_d_eerste_inschrijving: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_d_diploma: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_d_inschrijving: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_d_uitschrijving: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_des_uitschrijving: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_des_vertrekstatus: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_fun_volledige_naam: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_is_actueel: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_is_apcg: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_is_cohortstatus_tot_volledig: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nm_leerling: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nm_achternaam: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nm_basisschooladvies: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nm_basisschooladvies_duo: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nm_basisschooladvies_herzien: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nm_basisschooladvies_uni: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nm_basisschooladvies_uni_herzien: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nm_geslacht: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nm_nationaliteit1: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nm_nationaliteit2: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nm_niveau_lj3: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nm_onderwijssoort_svb: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nm_roepnaam: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nm_schoolsoort_svb: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nm_schoolsoort_svh: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nm_svb: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nm_svh: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nm_voorvoegsel: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nm_woonplaats: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nr_citototaalscore: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nr_examenopstroom_diploma: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nr_examenopstroom_instroom: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nr_iq: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nr_iq_punt: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nr_leerling: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nr_leerjaar_diploma: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nr_leerjaar_instroom: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nr_pc4: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_nr_uitstroompunt: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_pic_pasfoto: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ll_pk_key: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const dim_mw_medewerker: CumLaudeTableMetadata = {
    mw_abb_geslacht: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    mw_abb_medewerker: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    mw_co_externid: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    mw_d_indienst: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    mw_fun_d_uitdienst: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    mw_fun_is_docent: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    mw_fun_is_mentor: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    mw_fun_nr_dienstjaren: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    mw_fun_volledige_naam: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    mw_ids_vestiging: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    mw_is_ondersteunend: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    mw_is_onderwijzend: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    mw_nm_achternaam: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    mw_nm_emailadres: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    mw_nm_functie: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    mw_nm_medewerker: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    mw_nm_roepnaam: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    mw_nm_voorvoegsel: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    mw_nr_medewerker: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    mw_pic_pasfoto: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    mw_pk_key: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const dim_no_norm_onderwijsresultaat: CumLaudeTableMetadata = {
    no_co_indicator: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    no_d_geldig_tm: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    no_d_geldig_va: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    no_nm_indicator: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    no_nm_soort_onderwijs: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    no_nm_vergelijkgroep: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    no_nr_apcg_corr_factor: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    no_nr_instroom_corr_factor: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    no_nr_jobrun: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    no_nr_lwoo_corr_factor: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    no_nr_norm: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    no_nr_norm2: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    no_nr_opstroom_corr_factor: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    no_nr_peiljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    no_nr_versie: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    no_pk_key: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const dim_orvs_onderwijsresultaat_vestiging: CumLaudeTableMetadata = {
    orvs_co_brinvest: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    orvs_fk_inst: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    orvs_fks_vs: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    orvs_nm_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    orvs_nms_vestiging: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const dim_ov_onderwijspositie_vergelijkgroep: CumLaudeTableMetadata = {
    ov_co_brinvest: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ov_nm_vergelijkgroep: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ov_nr_peiljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const dim_pc_postcode: CumLaudeTableMetadata = {
    pc_nm_plaats: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    pc_nr_pc4: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const dim_per_periode: CumLaudeTableMetadata = {
    per_d_datum: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    per_abb_kwartaal: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    per_abb_weekdag: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    per_is_vakantie_midden: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    per_is_vakantie_noord: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    per_is_vakantie_zuid: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    per_is_weekend: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    per_nm_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    per_nr_dag: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    per_nr_datum: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    per_nr_isojaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    per_nr_isoweek: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    per_nr_jaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    per_nr_maand: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    per_nr_periode: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    per_nr_weekdag: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const dim_ppv_percentiel_prestatie_vso: CumLaudeTableMetadata = {
    ppv_is_prognose: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ppv_nm_indicator: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ppv_nm_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ppv_nm_vergelijkgroep: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ppv_nr_p25: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ppv_nr_p50: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    ppv_nr_p75: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const dim_vk_vak: CumLaudeTableMetadata = {
    vk_abb_vak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vk_co_vak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vk_nm_kernvak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vk_nm_vak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vk_nm_vak_kort: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vk_nm_vak_uni: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vk_nm_vakkengroep: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vk_nr_kernvak_volgorde: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const dim_vs_vestiging: CumLaudeTableMetadata = {
    vs_cos_brinvest: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vs_cos_brinvest_actief: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vs_d_geldig_va: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vs_d_geldig_tot: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vs_id_vestiging: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vs_is_actief: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vs_is_vo: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vs_is_vso: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vs_nm_vestiging: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const dim_vv_vestiging_vak: CumLaudeTableMetadata = {
    vv_abb_vak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vv_d_geldig_va: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vv_d_geldig_tot: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vv_id_vestiging: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vv_nm_vak: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vv_nm_vak_uni: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    vv_nm_vestiging: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const bvn_basisvaardigheden_norm: CumLaudeTableMetadata = {
    bvn_fk_inst: {
        type: "instelling",
        labelSuffix: undefined
    },
    bvn_fun_leerjaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bvn_fun_niveau: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bvn_id_vestiging: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bvn_nm_leerfase: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bvn_nm_schooljaar: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bvn_nm_vaardigheid: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bvn_nr_referentieniveau: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    bvn_nr_frac_doel: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const instelling: CumLaudeTableMetadata = {
    inst_nm_bron: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    inst_nm_instelling: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

const error_message: CumLaudeTableMetadata = {
    error: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    },
    parameters: {
        description: undefined,
        examples: undefined,
        label: undefined,
        searchKeys: undefined,
        filter: true,
        exportable: true
    }
}

export const metadataMap: CumLaudeTablesMetadata = {
    fac_aw_aanwezigheid: fac_aw_aanwezigheid,
    fac_aw_aanwezigheid_dag: fac_aw_aanwezigheid_dag,
    fac_aw_aanwezigheid_maand: fac_aw_aanwezigheid_maand,
    fac_aw_aanwezigheid_schooljaar: fac_aw_aanwezigheid_schooljaar,
    fac_bv_basisvaardigheden: fac_bv_basisvaardigheden,
    fac_ekc_examenkandidaten_en_cijfers: fac_ekc_examenkandidaten_en_cijfers,
    fac_cf_cijfer: fac_cf_cijfer,
    fac_ck_cijferkolommen: fac_ck_cijferkolommen,
    fac_ds_doorstroom: fac_ds_doorstroom,
    fac_lb_loopbaan: fac_lb_loopbaan,
    fac_lr_lesregistratie: fac_lr_lesregistratie,
    fac_or_onderwijsresultaten: fac_or_onderwijsresultaten,
    fac_pv_prestatieanalyse_vso: fac_pv_prestatieanalyse_vso,
    fac_vkk_vakkeuze: fac_vkk_vakkeuze,
    dim_br_brin: dim_br_brin,
    dim_clc_cijfer_label_conversie: dim_clc_cijfer_label_conversie,
    dim_ilt_opleidingscode: dim_ilt_opleidingscode,
    dim_kl_klas: dim_kl_klas,
    dim_lg_lesgroep: dim_lg_lesgroep,
    dim_ll_leerling: dim_ll_leerling,
    dim_mw_medewerker: dim_mw_medewerker,
    dim_no_norm_onderwijsresultaat: dim_no_norm_onderwijsresultaat,
    dim_orvs_onderwijsresultaat_vestiging: dim_orvs_onderwijsresultaat_vestiging,
    dim_ov_onderwijspositie_vergelijkgroep: dim_ov_onderwijspositie_vergelijkgroep,
    dim_pc_postcode: dim_pc_postcode,
    dim_per_periode: dim_per_periode,
    dim_ppv_percentiel_prestatie_vso: dim_ppv_percentiel_prestatie_vso,
    dim_vk_vak: dim_vk_vak,
    dim_vs_vestiging: dim_vs_vestiging,
    dim_vv_vestiging_vak: dim_vv_vestiging_vak,
    bvn_basisvaardigheden_norm: bvn_basisvaardigheden_norm,
    instelling: instelling,
    error_message: error_message
};
