import { Injectable, Signal } from '@angular/core';
import { AttrPath } from './data.service';
import { get, last } from 'lodash-es';
import { AttrMap } from './labels';
import { UserService } from './user.service';
import { RVestiging } from '@cumlaude/service-contract';
import { dutchDate } from '@cumlaude/shared-utils';
import { formatDecimal } from '@cumlaude/shared-pipes';
import { toSignal } from '@angular/core/rxjs-interop';

const nullValues: AttrMap = {
	aw_nm_abs_reden: 'Aanwezig',

	cf_nm_bijzonderheid: 'Geen',
	cf_nm_eerder_geexamineerd: 'Niet van toepassing',

	ds_fun_basisschooladvies_duo: 'Onbekend/Niet gevuld',
	ds_nm_uitstroom_in_schooljaar: 'Geen',
	ds_nm_uitstroom: 'Geen',
	ds_nm_uitstroommoment_in_schooljaar: 'Geen',
	ds_nm_uitstroomtype_in_schooljaar: 'Geen',

	ilt_abb_profiel: 'Geen',
	ilt_nm_profiel: 'Geen',

	lb_nm_instroom_in_schooljaar: 'Geen',
	lb_nm_instroom: 'Geen',
	lb_nm_instroommoment_in_schooljaar: 'Geen',
	lb_nm_instroommoment: 'Geen',
	lb_nm_instroomtype_in_schooljaar: 'Geen',
	lb_nm_instroomtype: 'Geen',
	lb_nm_uitstroom_in_schooljaar: 'Geen',
	lb_nm_uitstroom: 'Geen',
	lb_nm_uitstroommoment_in_schooljaar: 'Geen',
	lb_nm_uitstroommoment: 'Geen',
	lb_nm_uitstroomtype_in_schooljaar: 'Geen',
	lb_nm_uitstroomtype: 'Geen',
	lb_nm_vorige_vestiging: 'Extern',

	ll_des_uitschrijving: 'Niet van toepassing',
	ll_nm_basisschooladvies_uni_herzien: 'Onbekend/Niet gevuld',
	ll_nm_basisschooladvies_uni: 'Onbekend/Niet gevuld',
	ll_nm_basisschooladvies: 'Niet gevuld',
};

export const NULLVALUE_LABEL = 'Onbekend';

@Injectable({
	providedIn: 'root',
})
export class DisplayService {
	private vestigingen: Signal<RVestiging[]>;

	constructor(userService: UserService) {
		this.vestigingen = toSignal(userService.rVestigingen$, { initialValue: [] });
	}

	display(dbValue: any, attr?: AttrPath): string {
		if (dbValue !== null) {
			if (last(attr)?.split('_')[1] === 'd') return dutchDate(dbValue);
			if (attr?.join('.') == 'ekc_fk_br_vest.br_co_brin') return this.generateBrinVestigingValueString(dbValue);
			if (typeof dbValue === 'number' && !last(attr)?.endsWith('_nr_leerling')) return formatDecimal(dbValue, undefined);

			return `${dbValue}`;
		}

		if (!attr) return NULLVALUE_LABEL;

		for (let i = 0; i < attr.length; i++) {
			const v = get(nullValues, attr.slice(i));
			if (v) return v;
		}
		return NULLVALUE_LABEL;
	}

	generateBrinVestigingenValueString(brinVests?: string[]): string {
		return this.display(brinVests?.map((brinVest) => this.generateBrinVestigingValueString(brinVest)).join(', '), ['br_co_brin']);
	}

	generateBrinVestigingValueString(brinvest: string) {
		if (brinvest == null) return NULLVALUE_LABEL;

		const vestigingNamen = this.vestigingen()
			.filter((vestiging) => vestiging.actief)
			.filter((vestiging) => vestiging.vestigingBrinsActief.includes(brinvest))
			.map((vestiging) => vestiging.naam)
			.filter((value, index, array) => array.indexOf(value) === index);
		const namen = vestigingNamen.length > 0 ? ` (${vestigingNamen.join(', ')})` : '';
		return `${brinvest}${namen}`;
	}
}
