import { Categorie } from '../exportable';

export const leerling_leerling: Categorie = {
	label: 'Leerling',
	atts: [
		{ att: 'x_leerlingselectie', isExportable: false, searchKeys: ['Eigen filter', 'Persoonlijk', 'Opgeslagen', 'Favoriet'] },
		{ att: 'll_nm_leerling', example: 'Vries, Lucas de (123456); Jong, Julia de (23456); ...' },
		{ att: 'll_nm_roepnaam', example: 'Lucas, Julia, ...' },
		{ att: 'll_nm_achternaam', example: 'Vries, Jong, ...' },
		{ att: 'll_nm_voorvoegsel', example: 'de, van, ...' },
		{ att: 'll_nm_geslacht', example: 'Man, Vrouw' },
		{ att: 'll_nm_nationaliteit1', example: 'Nederlandse, Turkse, ...', isFilter: false },
		{ att: 'll_nm_nationaliteit2', example: 'Nederlandse, Turkse, ...', isFilter: false },
		{ att: 'll_d_geboortedatum', example: '18-04-2011' },
		{ att: 'll_nr_leerling', example: '123456, 23456, ...' },
		{ att: 'll_nr_pc4', example: '1000 - 9950' },
		{ att: 'll_nm_woonplaats', example: 'Utrecht, Groningen, Maastricht, ...' },
	],
};

export const leerling_instroom_uitstroom: Categorie = {
	label: 'Instroom - Uitstroom',
	atts: [
		{ att: 'll_d_inschrijving', example: '01-08-2022' },
		{ att: 'll_d_uitschrijving', example: '13-06-2023' },
		{ att: 'll_des_uitschrijving', example: 'Geslaagd, Andere school, ...' },
		{ att: 'll_des_vertrekstatus', example: 'Geslaagd, Niet bevorderd, ...' },
	],
};

export const leerling_vooropleiding: Categorie = {
	label: 'Vooropleiding',
	atts: [
		{ att: 'll_nm_basisschooladvies', example: 'PrO, VMBO b, VMBO k, VMBO (g)t, HAVO, VWO, ...' },
		{ att: 'll_nm_basisschooladvies_uni', example: 'PrO, VMBO b, VMBO k, VMBO (g)t, HAVO, VWO, ...' },
		{ att: 'll_nm_basisschooladvies_uni_herzien', example: 'PrO, VMBO b, VMBO k, VMBO (g)t, HAVO, VWO, ...' },
		{ att: 'll_nm_svh', example: 'Basisschool De Regenboog', searchKeys: ['Basisschool', 'school van herkomst'] },
		{
			att: 'll_nm_schoolsoort_svh',
			searchKeys: ['school van herkomst'],
			example: 'BAO - Basisonderwijs, VO - Voorgezet onderwijs, Speciaal onderwijs, ...',
		},
		{ att: 'll_nr_citototaalscore', example: '501 - 550' },
	],
};

export const leerling_vervolgopleiding: Categorie = {
	label: 'Vervolgopleiding',
	atts: [
		{ att: 'll_nm_svb', searchKeys: ['school van bestemming'], example: 'Hogeschool CumLaude' },
		{ att: 'll_nm_schoolsoort_svb', searchKeys: ['school van bestemming'], example: 'HO, ROC, ...' },
		{ att: 'll_nm_onderwijssoort_svb', searchKeys: ['school van bestemming'], example: 'Voortgezet speciaal onderwijs, HBO opleiding, ...' },
	],
};
