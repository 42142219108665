import { ChangeDetectionStrategy, Component, inject, Input, Type, ViewChild } from '@angular/core';
import { DataService, ExportDataOptions } from '../../../services/data.service';
import { from, Observable, of, ReplaySubject, Subject, zip as rxZip } from 'rxjs';
import { flatMap, fromPairs, has, isEmpty, isFunction, isObject, isUndefined, last, omitBy, toPairs, zip } from 'lodash-es';
import { Sort, TableComponent } from '../../components/table/table/table.component';
import {
	AlternatingMode,
	CellDataType,
	CellDef,
	ColumnDef,
	createDefaultCellDef,
	createDefaultFooterCellDef,
	createDefaultHeaderCellDef,
	createModel,
	DEFAULT_FORMATS,
	ExportCellValue,
	Rowgroup,
	TableCellComponent,
	TableModel,
} from '../../components/table/table/table.model';
import { Router } from '@angular/router';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { isLastAtLevel, Level, PartialLevel, PartialPath, Path, sortAtLevel, sortByMeasure } from '../../../services/data-tree';
import { attrLabel } from '../../../services/labels';
import { DataCellComponent } from '../../components/table/cells/data-cell/data-cell.component';
import { PivotSeriesData } from '../../components/table/cells/pivot-series-header-cell/pivot-series-header-cell.component';
import { UrlService } from '../../../services/url.service';
import { ErrorMessage, ErrorMessageEnum } from '@cumlaude/metadata';
import { DataTreeTableConfig } from './data-tree-table-config';
import { Attributes, BaseDashboardConfig, LinkData } from '../base-dashboard/base-dashboard-config';
import { DashboardContext, isErrorContext, isHappyContext } from '../base-dashboard/dashboard-context';
import { BaseDashboardComponent } from '../base-dashboard/base-dashboard.component';
import { DisplayService } from '../../../services/display.service';
import { AsyncPipe } from '@angular/common';
import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { TooltipType } from '@cumlaude/shared-components-overlays';

export const DUMMY_ROW_ID = '_dummy';

export const BARCHART_COLUMN_NAME = 'barchart';
export const TOTAAL_LABEL = 'Totaal';

export enum ColumnType {
	GROUP = 'grouping',
	BARCHART = 'data',
	MEASURE = 'measures',
	PIVOT = 'pivot',
}

export type DataRow<A extends Attributes> = {
	_id: string;
	_path: Path<A, number[]>;
};

export type ExportColumnDef<A extends Attributes = Attributes> = {
	name: string;
	groupName?: string;
	type: CellDataType | ((rowModel: DataRow<A>) => CellDataType);
	format?: string | ((rowModel: DataRow<A>) => string | undefined);
};

export interface ExportTableColumnDef<A extends Attributes> extends ExportColumnDef<A> {
	getValue(rowModel: DataRow<A>): ExportCellValue | any;
	getExtraFooterValue?: () => ExportCellValue | any;
	getFooterValue?: () => ExportCellValue | any;
}

export interface ExportTable<A extends Attributes = Attributes> {
	data: { columns: ExportColumnDef<A>[]; rows: any[][]; extraFooter?: any[]; footer?: any[] }[];
	options: ExportDataOptions;
}

/**
 * De initialAttributes vormen de input voor de aggregators, op het diepste niveau van de boom.
 * Ze bestaan uit:
 * (a) {[groep-naam]: groep} voor alle groepen in subgroups
 * (b) {[measure-naam]: getalwaarde} voor alle measures uit de backend
 */
export function getInitialAttributes<I extends Attributes>(subgroupNames: string[], measureNames: string[], path: PartialPath<unknown, number[]>): I {
	return <I>{
		...getSubgroupAttributes(subgroupNames, path),
		...getDataMeasures(measureNames, <number[]>last(path)!.v),
		...getXaggs(last(path)!, measureNames),
	};
}

export function getDataMeasures<I extends Attributes>(measureNames: string[], data: number[]): Partial<I> {
	return <Partial<I>>fromPairs(zip(measureNames, data));
}

export function getSubgroupAttributes<I extends Attributes>(subgroupNames: string[], path: PartialPath<unknown, unknown>): Partial<I> {
	const subgroupLevels = path.slice(path.length - subgroupNames.length);
	return <Partial<I>>fromPairs(
		zip(
			subgroupNames,
			subgroupLevels.map((lvl) => lvl.k)
		)
	);
}

export function getXaggs(leaf: PartialLevel<unknown, number[]>, measureNames: string[]): { xa?: { [nr: number]: { [measure: string]: number } } } {
	if (leaf.xa === undefined) return {};

	const pairs = toPairs(leaf.xa).map(([k, v]) => [k, fromPairs(zip(measureNames, v))]);
	return { xa: fromPairs(pairs) };
}

export function getGroupAttributes<I extends Attributes, A extends Attributes>(
	context: DashboardContext<I, A, BaseDashboardConfig<I, A>>,
	path: PartialPath<A, unknown>
): { [n: string]: string | null } {
	const groupLevels = path.slice(1, context.groupNames.length + 1);
	return fromPairs(
		zip(
			context.groupNames,
			groupLevels.map((lvl) => lvl.k)
		)
	);
}

export function sortModels<I extends Attributes, A extends Attributes>(
	models: TableModel<DataRow<A>>[][][],
	sortState: Sort,
	context: DashboardContext<I, A, DataTreeTableConfig<I, A>>
): TableModel<DataRow<A>>[][][] {
	const direction = <'asc' | 'desc'>sortState.direction;
	const active: string = sortState.active;
	const firstModel = models[0]?.[0]?.[0];
	const colDef = firstModel?.columnDefs.find((def) => def.column === active);
	if (!active || !colDef || isEmpty(firstModel.data)) return models;
	let sorted;

	if (colDef.type === ColumnType.GROUP) {
		const levelNr = [...context.groupNames, ...context.subgroupNames].indexOf(active) + 1;
		sorted = sortAtLevel(context.dataRoot!, levelNr, direction);
	} else if (context.dataRoot!.r.length <= 1) {
		return models;
	} else {
		const pathToMeasure = (path: Path<A, number[]>) =>
			colDef.body.getValue({
				_id: DUMMY_ROW_ID,
				_path: path,
			});
		sorted = sortByMeasure(context.dataRoot!, pathToMeasure, direction);
	}

	switch (context.tableRootIndex) {
		case 0: {
			return [
				[
					[
						{
							...models[0][0][0],
							data: sorted.map((_path, ix) => ({ _path, _id: ix.toString() })),
						},
					],
				],
			];
		}
		case 1: {
			const newModels: TableModel<DataRow<A>>[] = [];
			sorted[0][0].c.forEach((child, ix) => {
				newModels[child.i] = {
					...models[0][0][ix],
					data: child.r.map((_path, ix) => ({ _path, _id: ix.toString() })),
				};
			});
			return [[newModels]];
		}
		case 3: {
			const newModels: TableModel<DataRow<A>>[][][] = [];
			sorted[0][0].c.forEach((level1, ix1) => {
				newModels[level1.i] = [];
				level1.c.forEach((level2, ix2) => {
					newModels[level1.i][level2.i] = [];
					level2.c.forEach((child, ix3) => {
						newModels[level1.i][level2.i][child.i] = {
							...models[ix1][ix2][ix3],
							data: child.r.map((_path, ix) => ({ _path, _id: ix.toString() })),
						};
					});
				});
			});
			return newModels;
		}
	}
}

export interface ColumnOptions<I extends Attributes, A extends Attributes> {
	context?: DashboardContext<I, A, DataTreeTableConfig<I, A>>;
	component: Type<Component & TableCellComponent<any>>;
	format?: string | ((path: Path<A, number[]>) => string);
	dataType?: CellDataType | ((rowModel: Path<A, number[]>) => CellDataType);
	tooltip?: TooltipType | ((rowModel: Path<A, number[]>) => TooltipType);
	header?: string;
	headerClass?: string;
	clickHandler?: (path: Path<A, number[]>) => void;
	footerClickHandler?: (path: Path<A, number[]>) => void;
	visible?: boolean | (() => boolean);
	exportable?: boolean | (() => boolean);
	columnType?: ColumnType;
}

const defaultColumnOptions: ColumnOptions<any, any> = {
	component: DataCellComponent,
	dataType: 'number',
};

export function createMeasureColumn<I extends Attributes, A extends Attributes>(
	column: string,
	getValue: (path: Path<A, number[]>) => any,
	options: Partial<ColumnOptions<I, A>> = {}
): ColumnDef<DataRow<A>> {
	const { context, component, format, header, headerClass, clickHandler, dataType, tooltip } = {
		...defaultColumnOptions,
		...options,
	};
	return {
		column,
		header: {
			...createDefaultHeaderCellDef<DataRow<A>>(column, header ?? column),
			class: headerClass ?? 'measure-column',
		},
		body: {
			...createDefaultCellDef<DataRow<A>>(column),
			component,
			getValue: (row) => getValue(row._path),
			format: isFunction(format) ? (row) => format(row._path) : format,
			dataType: isFunction(dataType) ? (row) => dataType(row._path) : dataType,
			tooltip: isFunction(tooltip) ? (row) => tooltip(row._path) : tooltip,
			clickHandler: clickHandler ? ({ _path }) => clickHandler(_path) : undefined,
		},
		footer: {
			...createDefaultFooterCellDef<DataRow<A>>(),
			component,
			getValue: (model) => (isEmpty(model.data) ? '' : getValue(tableRootPath(model, context))),
			format: isFunction(format) ? (model) => (isEmpty(model.data) ? '' : format(tableRootPath(model, context))) : format,
			dataType: isFunction(dataType) ? (model) => (isEmpty(model.data) ? 'string' : dataType(tableRootPath(model, context))) : dataType,
			clickHandler: getFooterClickHandler({ ...defaultColumnOptions, ...options }),
		},
		sortable: true,
		sticky: false,
		type: options.columnType ?? ColumnType.MEASURE,
		visible: options.visible ?? true,
		exportable: options.exportable ?? options.visible ?? true,
	};
}

function getFooterClickHandler<I extends Attributes, A extends Attributes>({
	footerClickHandler,
	clickHandler,
	context,
}: ColumnOptions<I, A>): ((model: TableModel<DataRow<A>>) => void) | undefined {
	if (footerClickHandler) return (model) => footerClickHandler(tableRootPath(model, context));
	if (clickHandler) return (model) => clickHandler(tableRootPath(model, context));
	return undefined;
}

function tableRootPath<I extends Attributes, A extends Attributes>(
	model: TableModel<DataRow<A>>,
	context?: DashboardContext<I, A, DataTreeTableConfig<I, A>>
): Path<A, number[]> {
	return model.data[0]._path.slice(0, (context?.tableRootIndex ?? 0) + 1);
}

export function createMeasureColumnWithExtraFooter<I extends Attributes, A extends Attributes>(
	column: string,
	getValue: (path: Path<A, number[]>) => any,
	getValueExtra: (path: Path<A, number[]>) => any,
	options: Partial<ColumnOptions<I, A>> = {}
): ColumnDef<DataRow<A>> {
	const coldef = createMeasureColumn(column, getValue, options);
	coldef.extraFooter = {
		...coldef.footer,
		getValue: (model) => (isEmpty(model.data) ? '' : getValueExtra(model.data[0]._path.slice(0, (options.context?.tableRootIndex ?? 0) + 1))),
	};
	return coldef;
}

/**
 * Als er met pivot is ge-unnest, zitten de records in "xr". Soms is dat niet zo en bevat het rij-level
 * gewoon 1 bar (r[0]). Om deze situaties gelijk te trekken geeft deze functie de records altijd in "r"
 * terug.
 */
export function getRowLevel<A>(path: Path<A, number[]>): Level<A, number[]> {
	const rowLevel = last(path)!;
	return rowLevel.xr ? { ...rowLevel, r: rowLevel.xr } : rowLevel;
}

/**
 * Functie die gebruikt kan worden in de enrichTableModel om een Rowgroup-object te genereren waarmee je op minder diepe levels dan het diepste
 * niveau van de boom de rijen kunt alterneren.
 * @param rowModel
 * @param alternatingGroupLevel Niveau gerekend vanaf het diepste niveau. 1 is het diepste niveau
 * @returns Rowgroup-object met een oplopend groupId op het gevraagde level
 */
export function getAlternatingRowgroup<A extends Attributes>(rowModel: DataRow<A>, alternatingGroupLevel: number): Rowgroup {
	const path = rowModel._path;
	return {
		groupId: getIndexOnLevel(path, path.length - alternatingGroupLevel),
		lastOfGroup: isLastAtLevel(path, path.length - alternatingGroupLevel),
	};
}

export function findChildOrEmpty<A, D>(parent: Level<A, D>, k: string | null): Level<A, D> {
	const found = parent.c.find((child) => child.k === k);
	return found || { k, c: [], r: [], i: -1, v: new Map(), a: <A>(<unknown>{ count_records: 0 }) };
}

function firstNEqual<T>(first: T[], second: T[], n: number): boolean {
	for (let i = 0; i < n; i++) if (first[i] !== second[i]) return false;
	return true;
}

/**
 * Berekent de index van een bepaald record op het gevraagde niveau, in de volledige breedte van de boom (dus niet alleen binnen zijn eigen siblings,
 * maar ook de cousins etc).
 */
function getIndexOnLevel<A extends Attributes>(path: Path<A, number[]>, level: number): number {
	const rootRecords = path[0].r;
	// Op het diepste niveau is het simpel:
	if (level >= path.length - 1) return rootRecords.indexOf(path);

	// De rootrecords zijn gesorteerd. We vergelijken steeds de eerste [level+1] niveaus van de paden.
	// De index is het aantal verschillende paden vóór het huidige.
	let n = 0;
	let previous: Path<A, number[]> | null = null;
	for (let rec of rootRecords)
		if (firstNEqual(rec, path, level + 1)) return n;
		else if (previous === null || !firstNEqual(rec, previous, level + 1)) {
			n++;
			previous = rec;
		}
	return n;
}

@Component({
	selector: 'app-data-tree-table',
	templateUrl: 'data-tree-table.html',
	styleUrls: ['../data-tree-table/data-tree-table.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf, TableComponent, AsyncPipe],
})
export class DataTreeTableComponent<I extends Attributes, A extends Attributes, C extends DataTreeTableConfig<I, A>> extends BaseDashboardComponent<
	I,
	A,
	C
> {
	@Input()
	showTitles = false;

	@Input()
	hoverHelp = false;

	@Input()
	exportable = true;

	public ErrorEnum: typeof ErrorMessageEnum = ErrorMessageEnum;

	private rowClick$ = new Subject<DataRow<A>>();

	@Input()
	barchartWidth: number = 400;

	@ViewChild(TableComponent)
	table!: TableComponent<DataRow<A>>;

	rowClick(row: DataRow<A>) {
		this.rowClick$.next(row);
	}

	protected dataService = inject(DataService);

	protected displayService: DisplayService = inject(DisplayService);

	/** Het ViewModel (tablemodel + sortering) */
	vm$ = new ReplaySubject<{ sortState: Sort; models: TableModel<DataRow<A>>[][][]; error?: ErrorMessage }>(1);

	constructor(
		protected router: Router,
		protected urlService: UrlService
	) {
		super(router, urlService);
		this.subscriptions.push(
			this.dashboardContext$
				.pipe(
					filter((value) => isHappyContext(value)),
					switchMap((context) =>
						this.rowClick$.pipe(map((row) => this.config.createLinkData(row._path, <DashboardContext<I, A, C>>context)))
					)
				)
				.subscribe((linkData) => this.handleClick(linkData))
		);
		// combineer alle wijzigingen van groepen en filters, haal daar data van op en maak hier een model van
		const tableModels$ = this.dashboardContext$.pipe(map((context) => this.makeTableModels(context)));

		this.subscriptions.push(
			rxZip(this.dashboardContext$, tableModels$)
				.pipe(
					switchMap(([context, tms]) => {
						if (isErrorContext(context)) {
							return of({ sortState: <Sort>{ active: '', direction: 'asc' }, models: [], error: context });
						}
						return this.qp.observe('sortOrder', this.config.getDefaultSort(tms[0]?.[0]?.[0])).pipe(
							map((sortState) => {
								return {
									sortState,
									models: sortModels(tms, sortState, context),
								};
							})
						);
					})
				)
				.subscribe(this.vm$)
		);
	}

	private makeTableModels(tableContext: DashboardContext<I, A, DataTreeTableConfig<I, A>> | ErrorMessage): TableModel<DataRow<A>>[][][] {
		if (isErrorContext(tableContext)) return [];

		const { dataRoot, tableRootIndex } = tableContext;
		if (!dataRoot) return [];

		let tableRoots;
		switch (tableRootIndex) {
			case 0:
				tableRoots = [[[dataRoot]]];
				break;
			case 1:
				tableRoots = [[dataRoot.c]];
				break;
			case 3:
				tableRoots = dataRoot.c.map((child) => child.c.map((subChild) => subChild.c));
				break;
		}

		return tableRoots.map((level1) => level1.map((level2) => level2.map((tableRoot) => this.makeTableModel(tableRoot, tableContext))));
	}

	private makeTableModel(tableRoot: Level<A, number[]>, tableContext: DashboardContext<I, A, DataTreeTableConfig<I, A>>) {
		const model = <TableModel<DataRow<A>>>{
			...createModel(
				tableRoot.r.map((_path, ix) => ({
					_id: ix.toString(),
					_path,
				})),
				(row) => row._id
			),
			stickyHeaders: true,
			stickyFooters: false,
			showFooters: true,
			rowsClickable: true,
			alternating: this.getAlternatingMode(),
			getRowgroup: (rowModel: DataRow<A>) => getAlternatingRowgroup(rowModel, this.config.getAlternatingGroupLevel()),
			columnDefs: this.createColumns(tableContext, tableRoot),
		};
		this.config.enrichTableModel(tableContext, model);
		return model;
	}

	getTopLevelTitle(models: TableModel<DataRow<A>>[][]): string {
		return this.displayService.display(models[0][0].data[0]._path[1].k);
	}

	isTopLevelKeyEmpty(models: TableModel<DataRow<A>>[][]): boolean {
		return models[0][0].data[0]._path[1].k === null;
	}

	getTitle(model: TableModel<DataRow<A>>): string {
		const lvl = this.tableGroups?.length ?? 0;
		return this.displayService.display(model.data[0]._path[lvl].k);
	}

	isKeyEmpty(model: TableModel<DataRow<A>>): boolean {
		const lvl = this.tableGroups?.length ?? 0;
		return model.data[0]._path[lvl].k === null;
	}

	isIncrementalRender() {
		return !this.tableGroups?.length;
	}

	forceTotalRender(): Observable<void> {
		if (!this.isIncrementalRender()) return of(void 0);
		if (!this.table) return of(void 0);
		return from(this.table.forceTotalRender());
	}

	protected createColumnsPerType(
		context: DashboardContext<I, A, DataTreeTableConfig<I, A>>,
		_tableRoot: Level<A, number[]>
	): Partial<{ [key in ColumnType]: ColumnDef<DataRow<A>>[] }> {
		return {
			data: this.createDataColumns(context),
			grouping: context.config.createGroupingColumns(context),
			measures: context.config.createMeasureColumns(context),
		};
	}

	protected createColumns(context: DashboardContext<I, A, DataTreeTableConfig<I, A>>, tableRoot: Level<A, number[]>): ColumnDef<DataRow<A>>[] {
		const columns = this.createColumnsPerType(context, tableRoot);
		return flatMap(this.config.columnOrder, (cat) => columns[cat] ?? []);
	}

	protected createDataColumns(_context: DashboardContext<I, A, DataTreeTableConfig<I, A>>): ColumnDef<DataRow<A>>[] {
		return [];
	}

	protected handleClick(linkData: Partial<LinkData>) {
		const { redirect } = linkData;
		if (redirect) {
			this.urlService.navigate(linkData);
		} else {
			const { groups, filters } = linkData;
			this.config.handleClick(filters!, groups!);
		}
	}

	exportAsTable(options: ExportDataOptions): Observable<Blob> {
		return this.vm$.pipe(
			take(1),
			map(({ models }) => this.getExportTable(models, options)),
			switchMap((exportTable) => this.dataService.getTableExport(exportTable))
		);
	}

	protected getExportTitleColumns(): ExportTableColumnDef<A>[] {
		// Wanneer het dashboard (sub)titels boven de verschillende tabellen toont, voegen we deze als extra kolom(men) toe aan de data
		return (
			(this.showTitles &&
				this.tableGroups?.map((group, ix) => ({
					name: attrLabel(group),
					type: 'string',
					getValue: (row) => this.displayService.display(row._path[ix + 1].k),
				}))) ||
			[]
		);
	}

	protected getExportTableColumns(model: TableModel<DataRow<A>>): ExportTableColumnDef<A>[] {
		return model.columnDefs
			.filter((def) => def.column !== 'addGroup')
			.filter(
				({ type, exportable, visible }) =>
					type !== ColumnType.BARCHART &&
					(isFunction(exportable) ? exportable() : (exportable ?? (isFunction(visible) ? visible() : visible)))
			)
			.flatMap(({ header, body, extraFooter, footer, column }) => {
				const headerValue = getExportValue(model, header) || column;
				const component: TableCellComponent<any> = new body.component();
				const type = body.dataType ?? component.dataType ?? 'string';
				const format =
					body.format ??
					component.format ??
					(isFunction(type) ? (rowModel: DataRow<A>) => DEFAULT_FORMATS[type(rowModel)] : DEFAULT_FORMATS[type]);
				if (typeof headerValue === 'string') {
					return [
						{
							name: headerValue,
							type,
							format,
							getValue: (row) => getExportValue(row, body),
							getExtraFooterValue: () => getExportValue(model, extraFooter),
							getFooterValue: () => getExportValue(model, footer),
						},
					];
				} else if ('seriesKey' in headerValue && 'colKeys' in headerValue) {
					// column bevat PivotSeriesData:
					const seriesData = <PivotSeriesData>headerValue;
					return seriesData.colKeys.map((colKey, index) => ({
						name: colKey,
						groupName: index === 0 ? seriesData.seriesKey : undefined,
						type,
						format,
						getValue: (row) => {
							const data: any = body.getValue(row);
							return component.getExportValue ? component.getExportValue(data, index) : data;
						},
						getExtraFooterValue: () => getExportValue(model, extraFooter, index),
						getFooterValue: () => getExportValue(model, footer, index),
					}));
				}
				return [];
			});
	}

	protected getExportTable(models: TableModel<DataRow<A>>[][][], options: ExportDataOptions): ExportTable<A> {
		if (!models.length || !models[0].length) return { data: [], options };

		const titleColumns = this.getExportTitleColumns();
		const data = flatMap(models, (m) =>
			flatMap(m, (cm) =>
				cm.map((model) => {
					const columns = [...titleColumns, ...this.getExportTableColumns(model)];
					return {
						columns,
						rows: model.data.map((row) => columns.map((col) => col.getValue(row))),
						extraFooter: model.showExtraFooters ? columns.map((col) => col.getExtraFooterValue?.()) : undefined,
						footer: model.showFooters ? columns.map((col) => col.getFooterValue?.()) : undefined,
					};
				})
			)
		);
		return { data, options };
	}

	trackByIx(index: number, _item?: any) {
		return index;
	}

	getAlternatingMode() {
		return AlternatingMode.COLOR;
	}
}

function getExportValue<M, T>(model: M, def?: CellDef<M, T>, index?: number): ExportCellValue | any {
	if (def === undefined) return undefined;

	const component = new def.component();
	const data = def.getValue(model);
	const value = component.getExportValue ? component.getExportValue(data, index) : data;
	if (isObject(value) && has(value, 'value')) return value;
	const format = def.format ?? component.format;
	const type = def.dataType ?? component.dataType;
	if (format || type)
		return omitBy(
			{
				value,
				format: isFunction(format) ? format(model) : format,
				type: isFunction(type) ? type(model) : type,
			},
			isUndefined
		);
	return value;
}
