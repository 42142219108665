import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { AttrPath } from '../services/data.service';
import { Entry, EnumService, LegendaEnumNames } from '../core/services/enum.service';
import { generateCssClassForCohortdetails } from '../services/css-generate-metadata';
import { generateCssClassForString } from '@cumlaude/shared-utils';
import { NULLVALUE_LABEL } from '../services/display.service';
import { NgClickOutsideDelayOutsideDirective } from 'ng-click-outside2';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';

export interface LegendaEntry extends Entry {
	class: string;
}

export type LegendaStyle = 'PILL';

@Component({
	selector: 'app-legenda',
	templateUrl: './legenda.component.html',
	styleUrls: ['./legenda.component.scss'],
	standalone: true,
	imports: [ButtonComponent, NgClickOutsideDelayOutsideDirective],
})
export class LegendaComponent implements OnChanges {
	open: boolean = false;

	@Input()
	attribute!: AttrPath | [LegendaEnumNames];

	@Input()
	keyIsClassName = false;

	@Input()
	includeNull = false;

	@Input()
	exclude: string[] = [];

	@Input()
	style?: LegendaStyle;

	options!: LegendaEntry[];

	ngOnChanges(changes: SimpleChanges) {
		const entries = EnumService.legendaEnumEntries(this.attribute)
			.filter((entry) => !this.exclude.includes(entry.value))
			.map((entry) => ({ ...entry, class: this.getClass(entry) }));
		this.options = [...entries, ...this.getNullEntry()];
	}

	getClass({ key, value }: Entry) {
		if (this.style === 'PILL') {
			const { className } = generateCssClassForCohortdetails(value);
			return `${className} pill`;
		}
		return `${generateCssClassForString(this.keyIsClassName ? key : value)}`;
	}

	private getNullEntry(): LegendaEntry[] {
		return this.includeNull ? [{ key: null, value: NULLVALUE_LABEL, class: this.getClass({ key: null, value: NULLVALUE_LABEL }) }] : [];
	}
}
