import { Categorie } from '../exportable';

export const periode_datum: Categorie = {
	label: 'Datum',
	atts: [
		{ att: 'per_abb_kwartaal', example: '1, 2, 3, 4' },
		{ att: 'per_abb_weekdag', example: 'ma, di, wo, do, vr' },
		{ att: 'per_d_datum', example: '21-04-2023, ...' },
		{ att: 'per_is_vakantie_midden', example: 'Ja, Nee' },
		{ att: 'per_is_vakantie_noord', example: 'Ja, Nee' },
		{ att: 'per_is_vakantie_zuid', example: 'Ja, Nee' },
		{ att: 'per_is_weekend', example: 'Ja, Nee' },
		{ att: 'per_nm_schooljaar', example: '2022/2023, ...' },
		{ att: 'per_nr_dag', example: '1-31' },
		{ att: 'per_nr_datum', example: '20230421', isFilter: false },
		{ att: 'per_nr_isojaar', example: '2023' },
		{ att: 'per_nr_isoweek', example: '1-53' },
		{ att: 'per_nr_jaar', example: '2023, ...' },
		{ att: 'per_nr_maand', example: '1-12' },
		{ att: 'per_nr_weekdag', example: '1-5' },
	],
};
