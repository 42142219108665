import { Injectable } from '@angular/core';
import { CumLaudeTableMetadata, FieldMetadata, ForeignKeyMetadata, metadataMap } from '@cumlaude/metadata';
import { get, set } from 'lodash-es';
import { Attr, DWHTable } from './data.service';

type ForeignKeyTableMetadata = {
	[attr in keyof DWHTable]: CumLaudeTableMetadata;
};

@Injectable({
	providedIn: 'root',
})
export class MetadataService {
	foreignKeyMetadataMap: ForeignKeyTableMetadata;

	constructor() {
		this.foreignKeyMetadataMap = this.generateMetadataMap();
	}

	getFieldsByForeignKey(foreignKey: Attr) {
		const tableDataByForeignKey = get(this.foreignKeyMetadataMap, foreignKey);
		if (!tableDataByForeignKey) return undefined;

		const keys = [];
		for (let fieldKey of Object.keys(tableDataByForeignKey)) {
			const field = this.getFieldMetadata(tableDataByForeignKey, fieldKey);
			if (field) keys.push(fieldKey);
		}

		return keys;
	}

	private getFieldMetadata(tableData: CumLaudeTableMetadata, key: string): FieldMetadata | undefined {
		const metadata = get(tableData, key);
		if (!metadata) return undefined;

		return (metadata as ForeignKeyMetadata).type ? undefined : (metadata as FieldMetadata);
	}

	private generateMetadataMap(): ForeignKeyTableMetadata {
		const foreignKeyMetadataMap: ForeignKeyTableMetadata = {};

		for (let table of Object.keys(metadataMap)) {
			const tableData = get(metadataMap, table);
			if (!tableData) continue;

			for (let column of Object.keys(tableData)) {
				const metadata = get(tableData, column) as ForeignKeyMetadata;
				const targetMetadata = metadataMap[metadata?.type];

				if (!targetMetadata) continue;

				set(foreignKeyMetadataMap, column, targetMetadata);
			}
		}

		return foreignKeyMetadataMap;
	}
}
