import { Component, OnInit } from '@angular/core';
import { createMeasureColumn, DataRow, getInitialAttributes } from '../../shared/dashboard/data-tree-table/data-tree-table';
import { Niveau, Prestatie } from '@cumlaude/metadata';
import {
	AttrPath,
	BasicFilterExpression,
	CompoundFilterExpression,
	DataOptions,
	DataResponse,
	DataService,
	ExportDataOptions,
	FilterExpression,
} from '../../services/data.service';
import { FilterService } from '../../services/filter.service';
import { QueryParamStateService } from '../../services/query-param-state.service';
import { Observable } from 'rxjs';
import { FilterName } from '../../services/filter-config';
import { Level, PartialPath, Path } from '../../services/data-tree';
import { ColumnDef, createColumnDef, createDefaultHeaderCellDef, TableModel } from '../../shared/components/table/table/table.model';
import { fromPairs, isNil, isUndefined, last, sortBy, union, zip } from 'lodash-es';
import { MultilineCellComponent } from '../../shared/components/table/cells/multiline-cell/multiline-cell.component';
import { DoorstroomCellComponent, DoorstroomData } from '../../shared/components/table/cells/doorstroom-cell/doorstroom-cell.component';
import { deelVeilig, getSchooljaar, getSchooljaarTovHuidig, getSchooljarenRange } from '@cumlaude/shared-utils';
import { CheckboxComponent, FormDropdownComponent, Option } from '@cumlaude/shared-components-inputs';
import { att } from '../../services/measures';
import { LabelCellComponent } from '../../shared/components/table/cells/label-cell/label-cell.component';
import { Sort } from '../../shared/components/table/table/table.component';
import { formatPercent } from '@angular/common';
import { doorstroom_afk, sortLike } from '../../services/labels';
import { maxOverMapped, noAgg0, SingleAggregator, sumOver } from '../../services/aggregation';
import { PivotTableConfig } from '../../shared/dashboard/pivot-table/pivot-table-config';
import { Attributes, BaseDashboardConfig, LinkData } from '../../shared/dashboard/base-dashboard/base-dashboard-config';
import { DashboardContext } from '../../shared/dashboard/base-dashboard/dashboard-context';
import { FactTable } from '../../services/exportable';
import { ToastrService } from 'ngx-toastr';
import { DashboardVariant, Eenheid } from '../../services/weergave-opties';
import { getCellPath, PivotTableComponent } from '../../shared/dashboard/pivot-table/pivot-table.component';
import { TooltipElement } from '@cumlaude/shared-components-overlays';
import { SchooljaarHistorieOption } from '../../schooljaar-historie-filter/schooljaar-historie-option';
import { PsName } from '../../services/page-state.service';
import { formatDecimal } from '@cumlaude/shared-pipes';
import { DashboardHeaderComponent } from '../../dashboard-header/dashboard-header.component';
import { FilterPanelComponent } from '../../filter-panel/filter-panel.component';
import { DashboardContainerComponent } from '../../layout/dashboard-container/dashboard-container.component';

interface MatrixI extends Attributes {
	'ds_fk_ilt_naar.ilt_nm_niveau': Niveau | null;
	ds_nr_leerjaar_naar: string | null;
	ds_nm_idu: Prestatie | null;
	ds_fun_brinvest_overgang?: string | null;
	'ds_fk_ilt_naar.ilt_is_vavo': string;
	ds_fun_uitstroom_extern_matrix: string;
	ds_is_prognose: string;
	ds_nr_weging: number;
}

export interface MatrixA extends Attributes {
	count_zonder_idu_filter: number;
	weging: number;
	wegingZonderIduFilter: number;
	ds_is_prognose: number;
}

const OPTIONAL_2_DECIMALS = '1.0-2';

const LEERLINGEN_TELLEN_MEERDERE_KEREN_MEE_TOOLTIP = 'Leerlingen kunnen in meer dan 1 schooljaar meetellen voor dit totaal.';

@Component({
	selector: 'app-matrix',
	templateUrl: './matrix.component.html',
	styleUrls: ['./matrix.component.scss'],
	standalone: true,
	imports: [
		DashboardContainerComponent,
		FilterPanelComponent,
		DashboardHeaderComponent,
		FormDropdownComponent,
		CheckboxComponent,
		PivotTableComponent,
	],
})
export class MatrixComponent extends PivotTableConfig<MatrixI, MatrixA> implements OnInit {
	actueelFilters: FilterName[] = [
		'ds_nm_schooljaar_van',
		'ds_fk_lb_van.lb_co_brin',
		'ds_fk_vs_van.vs_nm_vestiging',
		'ds_fk_ilt_van.ilt_nm_niveau',
		'ds_nr_leerjaar_van',
		'ds_nm_idu',
	];

	historieFilters: FilterName[] = [
		'x_doorstroom_schooljaar_historie', //
		'x_doorstroom_multiselect_schooljaar',
		...this.actueelFilters,
	];

	fixedBeforeGroups = 1;

	groups: AttrPath[] = [['ds_fun_leerfase_plus_vavo']];

	filterExpressions?: FilterExpression[];

	permanentFilterExpressions: FilterExpression[] = [new BasicFilterExpression(['ds_is_relevante_doorstroom'], 1)];

	doorstroomLevelsZonderOvergang: AttrPath[] = [
		['ds_fk_ilt_naar', 'ilt_is_vavo'],
		['ds_fun_uitstroom_extern_matrix'],
		['ds_fk_ilt_naar', 'ilt_nm_niveau'],
		['ds_nr_leerjaar_naar'],
		['ds_is_prognose'],
		['ds_nm_idu'],
	];

	doorstroomLevelsMetOvergang: AttrPath[] = [
		['ds_fk_ilt_naar', 'ilt_is_vavo'],
		['ds_fun_uitstroom_extern_matrix'],
		['ds_fk_ilt_naar', 'ilt_nm_niveau'],
		['ds_fun_brinvest_overgang'],
		['ds_nr_leerjaar_naar'],
		['ds_is_prognose'],
		['ds_nm_idu'],
	];

	toonBrinOvergang!: boolean;

	variant!: DashboardVariant;

	eenheid!: Eenheid;

	eenheidOpties = Object.values(Eenheid).map((val) => new Option(val));

	schooljaren?: string[];

	legendaExcludes: Prestatie[] = [Prestatie.GEEN];

	constructor(
		protected dataService: DataService,
		protected filterService: FilterService,
		public qp: QueryParamStateService,
		protected toastr: ToastrService
	) {
		super(filterService, toastr);
	}

	ngOnInit() {
		this.subscribeToQueryParams();
	}

	subscribeToQueryParams() {
		this.subscriptions.push(
			this.qp.observe('brin-overgang').subscribe((overgang) => (this.toonBrinOvergang = overgang)),
			this.qp.observe('eenheid').subscribe((eenheid) => {
				this.eenheid = eenheid;
				this.filterService.refresh();
			}),
			this.qp.observe('variant').subscribe((variant) => (this.variant = variant)),
			this.filterService.observeAsInput('ds_nm_schooljaar_van').subscribe((schooljaar) => {
				if (!schooljaar || this.variant !== DashboardVariant.ACTUEEL) return;

				this.schooljaren = [schooljaar, getSchooljaar(schooljaar, 1)];
			}),
			this.filterService.observeAsInput('x_doorstroom_schooljaar_historie').subscribe(({ option, inclHuidig }) => {
				if (this.variant !== DashboardVariant.HISTORIE || option === SchooljaarHistorieOption.AANGEPAST) return;

				let aantal = 0;
				switch (option) {
					case SchooljaarHistorieOption.AFGELOPEN_3:
						aantal = 3;
						break;
					case SchooljaarHistorieOption.AFGELOPEN_5:
						aantal = 5;
						break;
					case SchooljaarHistorieOption.AFGELOPEN_10:
						aantal = 10;
						break;
				}

				const laatsteJaar = getSchooljaarTovHuidig(inclHuidig ? 0 : -1);
				this.schooljaren = getSchooljarenRange(laatsteJaar, aantal);
			}),
			this.filterService.observeAsInput('x_doorstroom_multiselect_schooljaar').subscribe((value) => {
				if (this.variant !== DashboardVariant.HISTORIE || isUndefined(value)) return;

				this.schooljaren = value;
			})
		);
	}

	singleAggregators: Partial<{ [ai in keyof MatrixA]: SingleAggregator<MatrixI, MatrixA[ai]> }> = {
		count_zonder_idu_filter: <SingleAggregator<MatrixI, number>>noAgg0('count_records'),
		weging: sumOver<'ds_nr_weging', MatrixI, number>('ds_nr_weging'),
		wegingZonderIduFilter: <SingleAggregator<MatrixI, number>>noAgg0('ds_nr_weging'),
		ds_is_prognose: maxOverMapped<MatrixI>((v) => Number(v.ds_is_prognose)),
	};

	getSelectedEenheidOptie() {
		return this.eenheidOpties.find((optie) => optie.value === this.eenheid)!;
	}

	get fixedSubgroups() {
		return this.toonBrinOvergang ? this.doorstroomLevelsMetOvergang : this.doorstroomLevelsZonderOvergang;
	}

	factTable = FactTable.doorstroom;

	getData(options: DataOptions): Observable<DataResponse<number[]>> {
		// Verplaats ds_nm_idu naar "having" zodat we de totalen berekenen zonder op ds_nm_idu te filteren.
		const { f, having } = this.dataService.moveToHaving([['ds_nm_idu']], options);
		return this.dataService.getDoorstroomData({ ...options, f, having, r: [0, this.groups.length] });
	}

	getExportData(options: ExportDataOptions) {
		return this.dataService.getDoorstroomExportData(options);
	}

	enrichTableModel(_context: DashboardContext<MatrixI, MatrixA, MatrixComponent>, tableModel: TableModel<DataRow<MatrixA>>) {
		tableModel.columnDefs[0].sticky = true;
		tableModel.columnDefs[0].sortable = false;
		tableModel.columnDefs[0].header.getValue = () => `van ${this.schooljaren?.[0] ?? ''}`;
		tableModel.columnDefs[0].footer.getValue = () =>
			`${this.schooljaren?.length === 1 ? 'naar' : 't/m'} ${this.schooljaren ? last(this.schooljaren) : ''}`;

		tableModel.stickyFooters = true;
	}

	onContextCreated(context: DashboardContext<MatrixI, MatrixA, BaseDashboardConfig<MatrixI, MatrixA>>): void {
		this.pageStateService.dispatch(PsName.prognose, String(Boolean(context.dataRoot?.a.ds_is_prognose)));
	}

	getDefaultSort(_tableModel: TableModel<DataRow<MatrixA>>): Sort {
		return { active: '', direction: 'asc' };
	}

	isColumnKey = this._isColumnKey.bind(this);

	/**
	 * Deze functie wordt aangeroepen met een steeds langer colPath totdat hij true oplevert.
	 * Niveau + leerjaar zijn bepalend voor idu, dus als deze beide niet-null zijn vindt er geen
	 * splitsing meer plaats op idu.
	 */
	_isColumnKey(colPath: (string | null)[]): boolean {
		if (this.toonBrinOvergang) {
			const [_root, _vavo, _extern, niveau, _brin, leerjaar, prognose, _idu] = colPath;
			if (leerjaar && niveau && !isNil(prognose)) return true;
		} else {
			const [_root, _vavo, _extern, niveau, leerjaar, prognose, _idu] = colPath;
			if (leerjaar && niveau && !isNil(prognose)) return true;
		}
		return false;
	}

	columnSort = this._columnSort.bind(this);

	_columnSort(colPath: (string | null)[], keys0: (string | null)[], keys1: (string | null)[]) {
		const geslaagdEerst = [
			Prestatie.GESLAAGD,
			Prestatie.AFSTROOM,
			Prestatie.OPSTROOM,
			Prestatie.DOORSTROOM,
			Prestatie.DOUBLURE,
			Prestatie.AFGEWEZEN,
		];

		const attrs = ['root', ...this.fixedSubgroups.map((attrPath) => attrPath.join('.'))];
		const allKeys = sortBy(union(keys0, keys1));
		switch (attrs[colPath.length]) {
			case 'ds_fun_brinvest_overgang':
				return [...(allKeys.includes(null) ? [null] : []), ...allKeys.filter((x) => x)];
			case 'ds_fk_ilt_naar.ilt_nm_niveau':
				return sortLike(allKeys, Object.values(Niveau));
			case 'ds_nm_idu':
				return sortLike(allKeys, geslaagdEerst);
			default:
				return allKeys;
		}
	}

	createMeasureColumns(context: DashboardContext<MatrixI, MatrixA, MatrixComponent>): ColumnDef<DataRow<MatrixA>>[] {
		const lln = createMeasureColumn('lln', att('wegingZonderIduFilter'), { context });
		lln.body.class = 'lln';
		lln.header.getValue = () => '';
		lln.header.class = 'lln';
		lln.sortable = false;
		lln.sticky = true;
		lln.footer.getValue = () => '';
		lln.footer.component = LabelCellComponent;
		lln.footer.class = 'lln';
		lln.body.tooltip = LEERLINGEN_TELLEN_MEERDERE_KEREN_MEE_TOOLTIP;
		return [lln];
	}

	createPivotColumns(
		columnRoot: Level<MatrixA, number[]>,
		context: DashboardContext<MatrixI, MatrixA, MatrixComponent>
	): ColumnDef<DataRow<MatrixA>>[] {
		return columnRoot.r.map((path) => this.createPivotColumn(path, context));
	}

	private createPivotColumn(
		path: Path<MatrixA, number[]>,
		context: DashboardContext<MatrixI, MatrixA, MatrixComponent>
	): ColumnDef<DataRow<MatrixA>> {
		const { subgroupNames, measureNames } = context;
		const attrs = <MatrixI>getColumnAttributes(subgroupNames, path);
		const {
			ds_fun_brinvest_overgang,
			ds_nm_idu,
			'ds_fk_ilt_naar.ilt_is_vavo': iltNaarVavo,
			ds_fun_uitstroom_extern_matrix,
			ds_is_prognose,
		} = attrs;

		const isOvergang = !isNil(ds_fun_brinvest_overgang);
		const brinvest_overgang = isOvergang ? ` ${ds_fun_brinvest_overgang}` : '';
		const vavo = iltNaarVavo === '1' ? ' (VAVO)' : '';
		const extern = ds_fun_uitstroom_extern_matrix === '1' && ds_nm_idu !== Prestatie.GESLAAGD ? ' (ext)' : '';
		const prognose = Number(ds_is_prognose) ? ' (prog)' : '';

		const colName = `${doorstroom_afk(attrs, true)}${brinvest_overgang}${vavo}${extern}${prognose}`;
		const colId = path
			.slice(1)
			.map((lvl) => `${lvl.k}`)
			.join(';');
		const coldef = createColumnDef<DataRow<MatrixA>>(colId);
		coldef.header = createDefaultHeaderCellDef(colId, '');
		coldef.header.class = 'matrix';

		coldef.footer.class = 'matrix';
		coldef.footer.component = MultilineCellComponent;
		coldef.footer.clickHandler = () => this.handleFooterRedirect(context, attrs);
		coldef.footer.tooltip = LEERLINGEN_TELLEN_MEERDERE_KEREN_MEE_TOOLTIP;

		const footerVal = [`${formatDecimal(last(path)!.a.weging, OPTIONAL_2_DECIMALS)}`, colName];
		coldef.footer.getValue = () => footerVal;

		const colIndex = last(path)!.i;
		coldef.body.component = DoorstroomCellComponent;
		coldef.body.class = 'matrix';
		coldef.body.dataType = this.eenheid == Eenheid.PERCENTAGE ? 'percentage' : 'number';
		coldef.body.format = this.eenheid == Eenheid.PERCENTAGE ? '1.0-0' : OPTIONAL_2_DECIMALS;
		coldef.body.getValue = (row: DataRow<MatrixA>) => {
			const cellPath = getCellPath(row._path, colIndex);
			if (!cellPath) return null;
			const cellAttrs = getInitialAttributes<Omit<DoorstroomData, 'tooltip' | 'linkData'>>(subgroupNames, measureNames, cellPath);

			const leerfase_row = cellPath[1].k;
			const lln_cell = cellAttrs.ds_nr_weging;
			const lln_row = last(row._path)!.a.wegingZonderIduFilter;
			const percentage = deelVeilig(lln_cell, lln_row);

			if (this.eenheid == Eenheid.PERCENTAGE) cellAttrs.percentage = percentage;

			const tooltip: TooltipElement[] = [
				{ label: 'Doorstroom', value: `${leerfase_row} ➞ ${colName}` },
				{ label: 'Leerlingen', value: `${formatDecimal(lln_cell, OPTIONAL_2_DECIMALS)} van ${formatDecimal(lln_row, OPTIONAL_2_DECIMALS)}` },
				{ label: 'Leerlingen (%)', value: `${formatPercent(percentage, 'nl-NL')}` },
			];
			const linkData = context.config.createLinkData(cellPath, context);
			return { ...cellAttrs, tooltip, linkData };
		};
		return coldef;
	}

	createLinkData(path: Path<unknown, number[]>, context: DashboardContext<MatrixI, MatrixA, MatrixComponent>): Partial<LinkData> {
		return {
			dashboard: '/details/leerling/doorstroom',
			dataProvider: 'doorstroom',
			...super.createLinkData(path, context),
		};
	}

	private handleFooterRedirect(context: DashboardContext<MatrixI, MatrixA, MatrixComponent>, attrs: MatrixI) {
		const {
			ds_nr_leerjaar_naar,
			ds_nm_idu,
			'ds_fk_ilt_naar.ilt_nm_niveau': nm_niveau_naar,
			ds_fun_brinvest_overgang,
			'ds_fk_ilt_naar.ilt_is_vavo': iltNaarVavo,
			ds_fun_uitstroom_extern_matrix,
			ds_is_prognose,
		} = attrs;

		const filters = [
			new BasicFilterExpression(['ds_fk_ilt_naar', 'ilt_nm_niveau'], nm_niveau_naar),
			new BasicFilterExpression(['ds_nr_leerjaar_naar'], ds_nr_leerjaar_naar),
			new BasicFilterExpression(['ds_fk_ilt_naar', 'ilt_is_vavo'], iltNaarVavo),
			new BasicFilterExpression(['ds_fun_uitstroom_extern_matrix'], ds_fun_uitstroom_extern_matrix),
			new BasicFilterExpression(['ds_is_prognose'], ds_is_prognose),
			context.filter,
		];

		if (!ds_nr_leerjaar_naar || !nm_niveau_naar) filters.push(new BasicFilterExpression(['ds_nm_idu'], ds_nm_idu));

		if (!isNil(ds_fun_brinvest_overgang)) filters.push(new BasicFilterExpression(['ds_fun_brinvest_overgang'], ds_fun_brinvest_overgang));

		this.urlService.navigate({
			dashboard: '/details/leerling/doorstroom',
			dataProvider: 'doorstroom',
			filter: new CompoundFilterExpression(filters),
		});
	}
}

function getColumnAttributes(subgroupNames: string[], path: PartialPath<unknown, unknown>): Partial<MatrixI> {
	const subgroupLevels = path.slice(1);
	return <Partial<MatrixI>>fromPairs(
		zip(
			subgroupNames,
			subgroupLevels.map((lvl) => lvl.k)
		)
	);
}
